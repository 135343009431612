import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { ContentWidthContainer } from "./";
import {
  BackgroundSvg,
  PrimaryLine,
  WhitePolygon,
  starGridBackground,
} from "./styled";
import { backgroundLight, darkBlue } from "../../style/colors";
import Header from "../Header";
import Footer from "../Footer";
export { ContentWidthContainer };

const MainContainer = styled.div``;
const InnerContainer = styled.div`
  padding-top: calc(var(--header-height, 0) + 64px);
  padding-bottom: 200px;
  background: ${backgroundLight};
  color: ${darkBlue};
  min-height: calc(100vh - var(--footer-height, 0));
  box-sizing: border-box;
  position: relative;
`;
const BackgroundTop = styled(BackgroundSvg)`
  height: calc(var(--header-height, 0) + 16px);
  mix-blend-mode: normal;
  ${starGridBackground}
`;
const BackgroundBottom = styled(BackgroundSvg).attrs({ $bottom: true })`
  mix-blend-mode: normal;
`;

const PageLayout = ({ bottomBackground = true, ...props }) => {
  return (
    <MainContainer {...props}>
      <InnerContainer>
        <Header />
        <BackgroundTop></BackgroundTop>
        {bottomBackground && (
          <BackgroundBottom>
            <WhitePolygon points="0,100 100,100 100,0" />
            <PrimaryLine x1="0" y1="0" x2="100" y2="50" />
          </BackgroundBottom>
        )}

        <ContentWidthContainer>
          <Outlet />
        </ContentWidthContainer>
      </InnerContainer>
      <Footer backgroundColor="#fff" />
    </MainContainer>
  );
};
export default PageLayout;
