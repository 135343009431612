// import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";

const opacityKeyframes = keyframes`
    0% {opacity: 1}
    20% {opacity: 0}
    25% {opacity: 1}
    40% {opacity: 0}
    45% {opacity: 1}
    50% {opacity: 1}
    55% {opacity: 0}
    60% {opacity: 0}
    80% {opacity: 1}
    95% {opacity: 0}
    100% {opacity: 1}
`;
// const opacityAnimation = css`
//   animation: ${opacityKeyframes} ${({ $flickerTime }) => $flickerTime}ms linear
//     infinite;
// `;
const opacityAnimation = css`
  animation: ${opacityKeyframes} 1000ms linear infinite;
`;

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function randn_bm(min, max, skew) {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) num = randn_bm(min, max, skew);
  // resample between 0 and 1 if out of range
  else {
    num = Math.pow(num, skew); // Skew
    num *= max - min; // Stretch to fill range
    num += min; // offset to min
  }
  return num;
}

const StarDiv = styled.div.attrs(
  ({ $flickerTime, $startPosition, $size, $blur, $spread }) => {
    let style = {
      top: `${$startPosition[0]}%`,
      left: `${$startPosition[1]}%`,
      width: `${$size}px`,
      height: `${$size}px`,
      boxShadow: `0 0 ${$blur}px ${$spread}px #fff`,
    };
    if ($flickerTime) {
      style.animationDuration = `${$flickerTime}ms`;
    } else {
      style.animation = "none";
    }
    return {
      style,
    };
  }
)`
  position: absolute;
  right: 0;
  border-radius: 50%;
  background-color: white;
  ${opacityAnimation};
`;

const Star = ({ startPosition }) => {
  const blur = randn_bm(0, 3, 1);
  const _size = randn_bm(0, 2, 2);
  const spread = randn_bm(0, 2, 3);
  const _position = startPosition || [
    Math.floor(Math.random() * 99) + 1,
    Math.floor(Math.random() * 99) + 1,
  ];
  const flickerTime =
    Math.random() < 0.5 ? randomIntFromInterval(5000, 50000) : 0;
  return (
    <StarDiv
      $size={_size}
      $blur={blur}
      $spread={spread}
      $startPosition={_position}
      $flickerTime={flickerTime}
    />
  );
};

Star.propTypes = {
  size: PropTypes.number, //px default 1
  startPosition: PropTypes.arrayOf(PropTypes.number), // % [top, left]
};
export default Star;
