import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import { EmText, BlockHeader, Paragraph } from "../MainLayout/styled";
// import pictureIoana from "../../assets/crew/Ioana A. Mereuta.jpg";
import pictureBart from "../../assets/crew/Bart Knuiman.jpg";
import pictureBarbara from "../../assets/crew/Barbara Krawczyk.jpg";
import pictureOrkun from "../../assets/crew/Orkun.png";
import pictureTimon from "../../assets/crew/Timon V.png";
import pictureThomas from "../../assets/crew/Thomas Ginn.jpg";
import pictureAlexander from "../../assets/crew/Alexander_Klippel.jpg";
import liLogo from "../../assets/linkedin-16.png";
import { primaryColor, secondaryColor } from "../../style/colors";

const MainContainer = styled.div``;

const CrewContainer = styled.div``;

const EmployeeContainer = styled.a.attrs({
  target: "linkedIn",
  rel: "noreferrer noopener",
})`
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: inherit;
  width: 210px;
  margin: 30px;
  text-align: center;
  position: relative;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`;
const Picture = styled.img`
  border-radius: 50%;
  border: 1px solid ${secondaryColor};
  padding: 3px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: top;
  filter: saturate(1.2);
`;
const Name = styled.div`
  font-weight: bold;
  margin: 0.5em 0;
`;
const Role = styled.div``;

const LinkedInLogoContainer = styled.div`
  background: ${primaryColor};
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 50%;
  position: absolute;
  top: 175px;
  left: calc(50% - 75px);
  transform: translate(-50%, -50%);
`;
const LiLogoImg = styled.img.attrs({ src: liLogo })`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
const LinkedInLogo = ({ size = 28 }) => {
  return (
    <LinkedInLogoContainer $size={size}>
      <LiLogoImg />
    </LinkedInLogoContainer>
  );
};

const crewMembers = [
  // {
  //   name: "Ioana A. Mereuta",
  //   role: "Sr. Project Manager WANDER",
  //   picture: pictureIoana,
  //   linkedIn: "https://www.linkedin.com/in/ioanamereuta/",
  // },
  {
    name: "Thomas Ginn",
    role: "Team lead WANDER Lab",
    picture: pictureThomas,
    linkedIn: "https://www.linkedin.com/in/thomas-felix-ginn/",
  },
  {
    name: "Bart Knuiman",
    role: "AR/VR Developer",
    picture: pictureBart,
    linkedIn: "https://www.linkedin.com/in/bart-knuiman/",
  },
  {
    name: "Barbara Krawczyk",
    role: "Visualisation Developer",
    picture: pictureBarbara,
    linkedIn: "https://www.linkedin.com/in/barbara-krawczyk-742296175/",
  },
  {
    name: "Orkun C. Tekeli",
    role: "3D Content Developer",
    picture: pictureOrkun,
    linkedIn: "https://www.linkedin.com/in/orkun-cagri-tekeli-5a195561/",
  },
  {
    name: "Timon Verduijn",
    role: "Immersive Experience Developer",
    picture: pictureTimon,
    linkedIn: "https://www.linkedin.com/in/timon-verduijn/",
  },
  {
    name: "prof. dr. Alexander Klippel",
    role: "Scientific adviser",
    picture: pictureAlexander,
    linkedIn: "https://www.linkedin.com/in/alexander-klippel-bba5403/",
  },
];

const Employee = ({ name, role, picture, linkedIn }) => {
  return (
    <EmployeeContainer href={linkedIn}>
      <Picture src={picture} />
      <LinkedInLogo link={linkedIn} />
      <div>
        <Name>{name}</Name>
        <Role>{role}</Role>
      </div>
    </EmployeeContainer>
  );
};

const MeetUs = () => {
  return (
    <MainContainer>
      <ContentWidthContainer>
        <BlockHeader>
          Meet the <EmText>crew</EmText>
        </BlockHeader>

        <Paragraph>
          Deriving from a natural passion for immersive technologies, meet our
          core team and engage with them on a conversation about how science
          meets technology.
        </Paragraph>
        <CrewContainer>
          {crewMembers.map((employee) => (
            <Employee key={employee.name} {...employee} />
          ))}
        </CrewContainer>
      </ContentWidthContainer>
    </MainContainer>
  );
};

export default MeetUs;
