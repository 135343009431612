import styled, { css } from "styled-components";
import { ContentWidthContainer } from "../../MainLayout";
import {
  HomeBlock,
  BackgroundSvg,
  PrimaryLine,
  PrimaryPolygon,
  WhitePolygon,
} from "../../MainLayout/styled";
import backgroundMan from "../../../assets/Wander_Man_01_vrijstaand.png";
import { ReactComponent as GreenBox } from "../../../assets/greenbox_man.svg";
import backgroundGreenBoxShadow from "../../../assets/Wander_Man_01_Green_Box_Shadow.png";
import grid from "../../../assets/Wander_Blok_patroon_02.svg";
import {
  backgroundDark,
  backgroundLightBlue,
  secondaryColor,
} from "../../../style/colors";
import { ArrowDown } from "../../Icons";
import StarrySky from "../../StarrySky";

/*
css grid:
const gridColor = "#ffffff";
const gridSize = "240px 240px";
const gridOffset = "left top";

 background: linear-gradient(to right, ${gridColor} 1px, transparent 1px),
    linear-gradient(to bottom, ${gridColor} 1px, transparent 1px);
background-repeat: repeat, repeat;
  background-position: ${gridOffset}, ${gridOffset};
  background-size: ${gridSize}, ${gridSize};
*/
const BlockContainer = styled(HomeBlock)`
  height: 100vh;
  background: url(${grid}),
    radial-gradient(
      circle at 80% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: repeat, no-repeat;
  background-position: left top, center top;
  background-size: auto, cover;
  color: #fff;
  position: relative;
  z-index: 0;
`;

const GuyContainer = styled.div`
  position: absolute;
  left: 55vw;
  width: 45vw;
  bottom: -200px;
  height: 110vh;
`;
const GuyContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const guyPositioning = css`
  position: absolute;
  height: 100%;
  width: auto;
  bottom: 0;
  left: 0px;
`;

const GuyImage = styled.img.attrs({ src: backgroundMan })`
  ${guyPositioning};
  left: -97.5vh;
`;

const GreenBoxImage = styled(GreenBox)`
  ${guyPositioning};
  color: ${secondaryColor};
`;
const GreenBoxShadowImage = styled.img.attrs({
  src: backgroundGreenBoxShadow,
})`
  ${guyPositioning};
`;

const AdditionalBackgroud = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
`;

const BottomBackgroundImage = styled(BackgroundSvg).attrs({ $bottom: true })`
  height: 300px;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  padding-top: calc(var(--header-height-home, 0) + 100px);
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
`;

const NextSectionButton = styled.button`
  width: 36px;
  height: 36px;
  font-size: 24px;
  position: absolute;
  bottom: 90px;
  left: calc(50% - 18px);
  border-radius: 50% 50%;
  border: none;
  padding: 0;
  background: transparent;
  color: #ffffff;
  z-index: 2;
  cursor: pointer;
  transition: transform 200ms;

  &:hover {
    opacity: 0.7;
    transform: scale(1.1);
  }
`;

const Block1 = ({ scrollToNext, children }) => {
  return (
    <BlockContainer>
      <AdditionalBackgroud>
        <BottomBackgroundImage>
          <PrimaryPolygon points="24,72.2 100,0 100,95" />
        </BottomBackgroundImage>

        <StarrySky showNightBackground={false} />

        <GuyContainer>
          <GuyContainerInner>
            <GuyImage />
            <GreenBoxShadowImage />
            <GreenBoxImage />
          </GuyContainerInner>
        </GuyContainer>

        <BottomBackgroundImage>
          <PrimaryLine x1="0" y1="65" x2="100" y2="95" />
          <WhitePolygon points="0,100 0, 95 24,72.2 100,95 100,100" />
        </BottomBackgroundImage>
      </AdditionalBackgroud>

      <ContentWidthContainer style={{ height: "100%" }}>
        <ContentContainer>{children}</ContentContainer>
      </ContentWidthContainer>
      <NextSectionButton onClick={scrollToNext}>
        <ArrowDown size={36} />
      </NextSectionButton>
    </BlockContainer>
  );
};
export default Block1;
