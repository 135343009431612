import styled from "styled-components";
import { Link } from "react-router-dom";
import WanderLogoSrc from "./WanderLogo";
import wurLogo from "../assets/Logo_WUR.png";
import { EmText } from "./MainLayout/styled";
import { backgroundLight, darkBlue, primaryColor } from "../style/colors";
import { starGridBackground } from "./MainLayout/styled";
import { ContentWidthContainer } from "./MainLayout";

const stripHeight = 50;

const MainContainer = styled.div`
  background: ${backgroundLight};
  width: 100%;
  color: ${darkBlue};
  position: relative;
  height: var(--footer-height, 0);
  padding-top: 48px;
  padding-bottom: ${stripHeight + 48}px;
  box-sizing: border-box;

  & a {
    color: inherit;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const BottomStrip = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${stripHeight}px;
  ${starGridBackground};

  @media (max-width: 768px) {
    position: static;
    margin-top: 48px;
  }
`;

const WanderLogo = styled(WanderLogoSrc)`
  color: ${darkBlue};
  width: 200px;
  display: block;
`;
const WURLogo = styled.img.attrs({ src: wurLogo })`
  width: 200px;
`;
const Slogan = styled.div`
  font-size: 32px;
`;

const Col = styled.div`
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;

  @media (max-width: 768px) {
    margin-top: 32px;
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Col1 = styled(Col)`
  border-right: 2px solid ${primaryColor};

  @media (max-width: 768px) {
    border-right: none;
  }
`;
const Col2 = styled(Col)`
  padding-left: 48px;
  display: inline-flex;
  justify-content: space-between;
  line-height: 2;

  @media (max-width: 768px) {
    display: block;
    padding-left: 0;
  }
`;

const Col2A = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FooterHeader = styled.h4`
  margin-top: 0;
`;

const NavigationContainer = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledLink = styled(Link)`
  text-transform: uppercase;
  display: block;
`;
const navItems = [
  { to: "/", label: "Home" },
  { to: "/xr-projects", label: "XR-projects" },
  { to: "/sector", label: "Sector" },
  { to: "/about", label: "About WANDER" },
];

const Footer = ({ backgroundColor = backgroundLight }) => {
  return (
    <MainContainer style={{ background: backgroundColor }}>
      <ContentWidthContainer style={{ height: "100%" }}>
        <Col1>
          <WanderLogo />
          <WURLogo />
          <Slogan>
            Turning intangible data into{" "}
            <EmText>3D visualisations and immersive</EmText> experiences
          </Slogan>
        </Col1>
        <Col2>
          <Col2A>
            <FooterHeader>WANDER</FooterHeader>
            <NavigationContainer>
              {navItems.map((navItem) => (
                <StyledLink to={navItem.to} key={navItem.label}>
                  {navItem.label}
                </StyledLink>
              ))}
            </NavigationContainer>
          </Col2A>
          <div>
            <div style={{ marginBottom: 12 }}>
              <FooterHeader>Email</FooterHeader>
              <a href="mailto:wander@wur.nl">wander@wur.nl</a>
            </div>
            <b>Wageningen University & Research</b>
            <br />
            Droevendaalsesteeg 3 <br />
            6708 PB Wageningen <br />
            The Netherlands
          </div>
        </Col2>
      </ContentWidthContainer>
      <BottomStrip />
    </MainContainer>
  );
};

export default Footer;
