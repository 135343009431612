import styled from "styled-components";

export const ContentWidthContainer = styled.div`
  max-width: 1200px;
  padding-left: calc(16px + 4vw);
  padding-right: calc(16px + 4vw);
  margin: 0 auto;

  @media (min-width: 1200px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`;
