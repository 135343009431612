import styled from "styled-components";
// import backgroundImage from "../assets/15410.jpg";
import StarrySky from "./StarrySky";

import FlipCard from "./FlipCard";
import SvgGlow from "./SvgGlow";

const MainContainer = styled.div`
  height: 100vh;
  color: #ffffff;
  padding: 5%;
  box-sizing: border-box;
`;

const CardFace = styled.div``;

const Demo = () => {
  return (
    <MainContainer>
      <StarrySky />
      <h1>WANDER.</h1>
      <div>
        {/* <div style={{ width: 500, height: 400, background: "red" }}>
          Test block
        </div> */}
        <div>
          <div style={{ width: 400, height: 300, display: "inline-block" }}>
            <SvgGlow />
          </div>
          <div style={{ width: 400, height: 300, display: "inline-block" }}>
            <SvgGlow glow={false} />
          </div>
        </div>
        <FlipCard>
          <CardFace>Front</CardFace>
          <CardFace>Back</CardFace>
        </FlipCard>
        <FlipCard>
          <CardFace>Front</CardFace>
          <CardFace>Back</CardFace>
        </FlipCard>
      </div>
      {/* <a
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          color: "#ffffff",
        }}
        href="https://www.freepik.com/vectors/background"
      >
        Background vector created by starline - www.freepik.com
      </a> */}
    </MainContainer>
  );
};

export default Demo;
