import {
  PageHeader,
  ParagraphHeader,
  Paragraph,
  EmText,
} from "../../MainLayout/styled";
// import Testimonial from "../Testimonial";
import VideoEmbedResponsive from "../../Media/VideoEmbedResponsive";
import { MainContainer, VideoCaption, ContentText } from "../styled";
import treePollenImg from "./treePollen.png";

const TreePollen = () => {
  return (
    <MainContainer id="main">
      <PageHeader>Tree pollen</PageHeader>
      <VideoEmbedResponsive
        title="Embedded Media titled: Wander Digital Twins"
        width="560"
        height="315"
        src="https://wur.yuja.com/V/Video?v=192544&node=821337&a=127750649&preload=false"
      >
        <img src={treePollenImg} alt="video still" />
      </VideoEmbedResponsive>
      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Tree pollen:</b> Unity application of WUR campus
      </VideoCaption>
      <ContentText>
        <ParagraphHeader>Challenge - What do we see</ParagraphHeader>
        <Paragraph>
          PoC visualisation of the WUR campus– in which tree pollen distribution
          is simulated during daytime, influenced by wind speed and wind
          direction, and depends on the amount and types of trees present on the
          campus (color-coded: black, blue, and purple). The visual allows you
          to explore the pollen distribution through heat maps per tree type and
          with your cursor you can explore the pollen count on an exact
          location. Additionally, you can explore the dynamics of pollen
          distribution on campus through interaction, by altering the wind
          speed, direction and by planting trees.
        </Paragraph>

        <ParagraphHeader>Objective</ParagraphHeader>
        <Paragraph>
          To investigate the use of interactive 3D visualisation, and to develop
          a framework for visualisations in digital twin. Additionally, the
          project provided insight in what is necessary in order to integrate
          interactive 3D visualisations in DT projects.
        </Paragraph>

        <ParagraphHeader>New insights</ParagraphHeader>
        <Paragraph>
          On how interactive 3D visualisations can make a digital twin (DT):
          interactive, accessible and understandable. General conclusion was
          that including a visualisation will help you in the development of
          your DT to target your DT to your audience, to make complex content
          understandable and to identify knowledge gaps, however it takes
          considerable effort and an iterative process.
        </Paragraph>

        <ParagraphHeader>Future applications</ParagraphHeader>
        <Paragraph>
          Functions as a PoC meaning, that the exploratory project mainly
          stimulates development of future applications through interactive
          workshops on what the application can do already and how this might
          apply to other research fields.
        </Paragraph>

        {/* <ParagraphHeader>Testimony</ParagraphHeader>
        <Testimonial quote="Lorem ipsum" name="Ipsum Lorem" /> */}
      </ContentText>
    </MainContainer>
  );
};

export default TreePollen;
