// import styled from "styled-components";
import {
  PageHeader,
  ExternalLink,
  // ParagraphHeader,
  // Paragraph,
  EmText,
} from "../../MainLayout/styled";
// import Testimonial from "../Testimonial";
import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import {
  MainContainer,
  VideoCaption,
  VideoCaptionDescription,
  //  ContentText
} from "../styled";
import cropViewerImg from "./cropViewer.png";

const ProjectPage = () => {
  return (
    <MainContainer>
      <PageHeader>Virtual Tomato - Crop Viewer</PageHeader>

      <YoutubeEmbed videoCode="XmuaYtCB_4U">
        <img src={cropViewerImg} alt="video still" />
      </YoutubeEmbed>

      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Virtual Tomato - Crop Viewer</b>
        <VideoCaptionDescription>
          The Virtual Tomato Crops project aims to develop a simulation model
          that predicts tomato plant growth in 3D. For more information visit{" "}
          <ExternalLink href="https://www.wur.nl/en/project/Virtual-tomato-crops-1.htm">
            www.wur.nl/en/project/Virtual-tomato-crops-1.htm
          </ExternalLink>
        </VideoCaptionDescription>
      </VideoCaption>

      {/* <ContentText>
        <ParagraphHeader>Paragraph1</ParagraphHeader>
        <Paragraph>LoremIpsum</Paragraph>
      </ContentText> */}
    </MainContainer>
  );
};

export default ProjectPage;
