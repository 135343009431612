import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import {
  BackgroundSvg,
  PrimaryLine,
  WhitePolygon,
  PrimaryPolygon,
  EmText,
  BlockHeader,
  ParagraphHeader,
  Paragraph,
} from "../MainLayout/styled";
import { darkBlue } from "../../style/colors";
import grid from "../../assets/Wander_Blok_patroon_02.svg";

import studente from "../../assets/Wander_Studente_Vrijstaand.png";
import greenBox from "../../assets/Wander_Studente_Green_Box.png";
import greenBoxShadow from "../../assets/Wander_Studente_Green_Box_Shadow.png";

const MainContainer = styled.div`
  color: ${darkBlue};
  position: relative;
  padding-bottom: 180px;
`;

const ContentBlock = styled.div`
  width: 50%;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  /* clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%); */
`;

const BackgroundShapes = styled(BackgroundSvg)`
  mix-blend-mode: normal;
  height: 200px;
  margin-bottom: 1px;
`;

const Grid = styled.div`
  position: absolute;
  height: 200px;
  bottom: 0;
  left: 0;
  right: 0;

  background: url(${grid});
  background-repeat: repeat;
  background-position-y: 50px;

  clip-path: polygon(0% 0%, 0% 70%, 35% 35%);
`;

const MainImageContainer = styled.div`
  position: absolute;
  bottom: 0.2vw;
  left: calc(50vw - 320px);
  width: 100vw;
  height: 75vh;
  max-width: 950px;
`;
const MainImageContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MainImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
`;

const Education = () => {
  return (
    <MainContainer>
      <BackgroundContainer>
        <MainImageContainer>
          <MainImageContainerInner>
            <MainImage src={studente} />
            <MainImage src={greenBox} />
            <MainImage src={greenBoxShadow} />
          </MainImageContainerInner>
        </MainImageContainer>
        <BackgroundShapes $bottom>
          <WhitePolygon points="0,0 100,100 0,100" />
          <PrimaryLine x1="0" y1="0" x2="100" y2="100" />
          <PrimaryPolygon points="0,0 0,70 35,35" />
        </BackgroundShapes>
        <Grid />
      </BackgroundContainer>

      <ContentWidthContainer>
        <BlockHeader>
          The <EmText>education</EmText> journey
        </BlockHeader>

        <ContentBlock>
          <Paragraph>
            The skills of the future. We want to create awareness about
            advancements in the technological world in facilitate teachers and
            students a plug and play usage of the most advanced techniques. By
            collaborating with WANDER, students will get closer to the potential
            appliances of immersive technologies and together with their
            teachers explore new ways to interact and interpret data.
          </Paragraph>

          <ParagraphHeader>
            Lines of <EmText>interaction</EmText> with the lab
          </ParagraphHeader>
          <Paragraph>
            <ul>
              <li>Let student explore the use of cutting-edge technologies</li>
              <li>
                Internship and thesis projects embedded within WANDER's work
              </li>
              <li>
                Guest lecture on the uses data experience and the potential it
                has to advance research
              </li>
            </ul>
          </Paragraph>
        </ContentBlock>
      </ContentWidthContainer>
    </MainContainer>
  );
};

export default Education;
