// import styled from "styled-components";
import {
  PageHeader,
  ParagraphHeader,
  Paragraph,
  EmText,
} from "../../MainLayout/styled";
import Testimonial from "../Testimonial";
// import ResponsiveIFrame from "../../Media/ResponsiveIFrame";
import { MainContainer, VideoCaption, ContentText, MainImage } from "../styled";
import metabolomicsImg from "./metabolomics.png";

const ProjectPage = () => {
  return (
    <MainContainer>
      <PageHeader>Metabolomics</PageHeader>

      {/* <ResponsiveIFrame src="http://www.zerodelay.nl/mol6/index.html" /> */}
      <a
        href="http://www.zerodelay.nl/mol6/index.html"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <MainImage src={metabolomicsImg} />
      </a>

      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Metabolomics:</b> web-based 3D application
      </VideoCaption>

      <ContentText>
        <ParagraphHeader>Challenge - What do we see</ParagraphHeader>
        <Paragraph>
          Molecular space visualiser – in which compounds are situated closely
          together based on spectral similarity. These 3D data were obtained
          through advanced machine learning methods such as t-SNE and UMAP which
          allowed to reduce the mass spectral embedded space to three
          dimensions. You can explore and select specific chemical compound
          classes to be able to observe global clustering patterns.
        </Paragraph>

        <ParagraphHeader>Objective</ParagraphHeader>
        <Paragraph>
          Make students and researchers enthusiastic and knowledgeable on using
          mass spectral embeddings for metabolomics research by creating a 3D
          space in which to explore the mass spectral embeddings at current
          designed for educational purposes
        </Paragraph>

        <ParagraphHeader>New insights</ParagraphHeader>
        <Paragraph>
          The current webapp is already successful for making the people that
          have been testing the webapp enthusiastic about exploring the
          ‘milky-way’ of spectra and seeing the interesting topologies that are
          created by clustering spectra of similar compounds.
        </Paragraph>

        <ParagraphHeader>Future applications</ParagraphHeader>
        <Paragraph>
          Be able to upload your own embedding data and create visualisations of
          spectra from experimental samples as well as structure embeddings
          (where the 3D coordinates come from molecular properties rather than
          spectral properties).
        </Paragraph>

        <ParagraphHeader>Testimony</ParagraphHeader>
        <Testimonial
          quote="The collaboration: Inspired us to initiate proposals of unconventional interdisciplinary collaboration on molecular data."
          name="Justin vander Hooft"
        />
      </ContentText>
    </MainContainer>
  );
};

export default ProjectPage;
