import {
  PageHeader,
  ParagraphHeader,
  Paragraph,
  EmText,
} from "../../MainLayout/styled";
import Testimonial from "../Testimonial";
import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import { MainContainer, VideoCaption, ContentText } from "../styled";
import projectImg from "./proteinGame.jpg";

const ProteinGame = () => {
  return (
    <MainContainer>
      <PageHeader>Protein Game</PageHeader>

      <YoutubeEmbed videoCode="fsErLyZy9H0">
        <img src={projectImg} alt="" />
      </YoutubeEmbed>

      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Protein Game:</b> XR for Education
      </VideoCaption>

      <ContentText>
        <ParagraphHeader>Challenge</ParagraphHeader>
        <Paragraph>
          Biochemistry is the study of life at a molecular scale, and is
          especially concerned with a class of biological molecules called
          proteins. Proteins carry out all of the processes that allow life to
          exist and also cause many diseases and illnesses when they
          malfunction. This is why biochemistry will often be taught in colleges
          and universities as part of life science education programmes.
        </Paragraph>
        <Paragraph>
          As these processes are at a molecular scale they are invisible to
          humans and therefore abstract in nature. This poses a challenge for
          the teaching of biochemistry as the processes being taught are not
          tangible in the same way as other subjects that occur on a human scale
          and can therefore be understood more easily by students.
        </Paragraph>
        <Paragraph>
          Traditionally, biochemistry teaching relies on visualisations of
          molecular processes that are often made up of static imagery and
          sometimes two-dimensional video animations. These approaches to
          visualisation of what are highly dynamic processes does not convey an
          authentic representation of what is happening at the molecular level
          in cells.
        </Paragraph>

        <ParagraphHeader>Objective</ParagraphHeader>
        <Paragraph>
          To help students see a set of molecular processes in action.
          Specifically, the objective for this project is to create a highly
          dynamic, immersive, and gamified experience of a biochemical process
          known as the citric acid cycle. This is a series of eight separate
          reactions that are controlled by eight different proteins in virtually
          all forms of life and one of it’s roles is to extract energy from food
          molecules. It is also a process central to many other biochemical
          processes in the cell and therefore will be taught in all biochemistry
          curricula.
        </Paragraph>

        <ParagraphHeader>New insights</ParagraphHeader>
        <Paragraph>
          By creating protein and molecule models that are tens of thousands of
          times bigger than they are in our cells, students will be able to
          experience an authentic experience of how these proteins function in a
          highly dynamic and interactive way. This will allow students the
          opportunity to learn by doing rather than being passive in their
          education experience. Specifically, the proteins in the game will be
          enzymes that form a set of linked reactions known as the citric acid
          cycle – this set of eight reactions acts to extract energy from
          various food molecules such as sugars, fats and proteins.
        </Paragraph>
        <Paragraph>
          By allowing students the opportunity to explore this virtual world and
          interact with the processes being taught it will create new insights
          into the ways that students can learn through immersive technology.
        </Paragraph>

        <ParagraphHeader>Future applications</ParagraphHeader>
        <Paragraph>
          Biochemistry is a modular set of processes that are heavily
          interlinked, a bit like a train or road network. In the future we may
          be able to explore this world in a dynamic way as well as build and
          create new worlds in a modular way, interlinking one with the next.
        </Paragraph>
        <Paragraph>
          More widely, the work could be expanded beyond this proof of concept
          project to create an integrated form of teaching that allows students
          to explore realistic worlds at the same time as interacting with their
          educational materials in a different way from standard textbooks and
          lecture slides.
        </Paragraph>

        <ParagraphHeader>Testimony</ParagraphHeader>
        <Paragraph>
          This collaboration with Wander Lab has allowed the creation of a proof
          of concept to enhance teaching of a challenging subject. It is hoped
          that this new way of learning will ultimately create a better
          understanding of molecular processes in students who are learning this
          material as part of their studies.
        </Paragraph>
        <Paragraph>
          These new ideas will also hopefully go towards creating further rant
          proposals and bids for funding to maintain this collaboration between
          Wageningen University & Research and the University of Aberdeen.
        </Paragraph>
        <Testimonial
          quote="It has been a pleasure to work with Ioana and Orkun on this proof of concept project. They are easy to work with, attentive to the small details, and try to understand the processes and approach you would like to take even when the subject is not familiar to them.  My hope is this will turn into a much longer term collaboration that will allow us to develop these ideas far beyond our current proof of concept project."
          name="John Barrow, Dean for Entrepreneurship & Employability at University of Aberdeen"
        />
      </ContentText>
    </MainContainer>
  );
};

export default ProteinGame;
