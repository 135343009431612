import styled from "styled-components";
import { ContentWidthContainer } from "../../MainLayout";
import {
  HomeBlock,
  BackgroundSvg,
  LightPolygon,
  PrimaryLine,
  PrimaryPolygon,
  WhitePolygon,
} from "../../MainLayout/styled";
import grid from "../../../assets/Wander_Blok_patroon_02.svg";
import { backgroundDark, backgroundLightBlue } from "../../../style/colors";
import StarrySky from "../../StarrySky";

const BlockContainer = styled(HomeBlock)`
  padding-top: 100px;
  height: 100vh;
  box-sizing: border-box;
  background: url(${grid}),
    radial-gradient(
      circle at 0% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: repeat, no-repeat, no-repeat;
  background-size: auto, cover, auto;
  color: #fff;
  position: relative;
  overflow-x: hidden;
`;

const Block4 = ({ children }) => {
  return (
    <BlockContainer>
      <BackgroundSvg>
        <LightPolygon points="0,0 0,30 100,100 100,0" />
      </BackgroundSvg>

      <BackgroundSvg $bottom>
        <PrimaryLine x1="0" y1="0" x2="100" y2="70" />
        <PrimaryPolygon points="50,35 101,70 101,0" />
        <WhitePolygon
          points="-1,101 -1, 70 50,35 101,70 101,101"
          vectorEffect="non-scaling-stroke"
          style={{ strokeWidth: 1, stroke: "#fff" }}
        />
      </BackgroundSvg>
      <StarrySky showNightBackground={false} />

      <ContentWidthContainer style={{ height: "100%" }}>
        {children}
      </ContentWidthContainer>
    </BlockContainer>
  );
};
export default Block4;
