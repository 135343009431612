import styled from "styled-components";
import { BlockHeader } from "../../MainLayout/styled";

import {
  VR,
  AR,
  Displays,
  AI,
  Holograms,
  DataVisualisation,
  Projection,
  VirtualSpaces,
  AudioReactive,
  Gesture,
} from "../../Icons";

const ContentContainer = styled.div`
  padding-top: 64px;
  padding-bottom: 200px;
  height: 100%;
  box-sizing: border-box;
`;

const TechnologiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
`;

const TechnologyItem = styled.div`
  width: 20%;
  margin-bottom: 32px;
  text-align: center;

  @media (max-width: 768px) {
    width: 33.33%;
  }

  /* & svg {
    transition: transform 500ms;
  }
  & svg:hover {
    transform: scale(1.5) translateY(-10%);
  } */
`;

const TechnologyCaption = styled.div``;

const TechnologiesBlock = () => {
  return (
    <ContentContainer>
      <BlockHeader style={{ marginBottom: 12 }}>Technologies</BlockHeader>
      <TechnologiesContainer>
        <TechnologyItem>
          <VR size={100} />
          <TechnologyCaption>VR</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <AR size={100} />
          <TechnologyCaption>AR</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <Displays size={100} />
          <TechnologyCaption>Interactive Displays</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <AI size={100} />
          <TechnologyCaption>AI</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <Holograms size={100} />
          <TechnologyCaption>Holograms</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <DataVisualisation size={100} />
          <TechnologyCaption>Data Visualisation</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <Projection size={100} />
          <TechnologyCaption>Projection Mapping</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <VirtualSpaces size={100} />
          <TechnologyCaption>Virtual Spaces</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <AudioReactive size={100} />
          <TechnologyCaption>Audio Reactive</TechnologyCaption>
        </TechnologyItem>
        <TechnologyItem>
          <Gesture size={100} />
          <TechnologyCaption>Gesture Controlled</TechnologyCaption>
        </TechnologyItem>
      </TechnologiesContainer>
    </ContentContainer>
  );
};
export default TechnologiesBlock;
