// import styled from "styled-components";
import {
  PageHeader,
  // ParagraphHeader,
  Paragraph,
  // EmText,
} from "../../MainLayout/styled";
// import Testimonial from "../Testimonial";
// import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import {
  MainContainer,
  // VideoCaption,
  ContentText,
} from "../styled";
import proteinTransitionImg from "./proteinTransition.jpg";

const ProjectPage = () => {
  return (
    <MainContainer>
      <PageHeader>Protein Transition </PageHeader>

      {/* ?<YoutubeEmbed videoCode="xxxxxxxxxxx" /> */}

      {/* <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>ProjectTitle:</b> ProjectSlogan
      </VideoCaption> */}

      <ContentText>
        {/* <ParagraphHeader>Paragraph1</ParagraphHeader> */}
        <Paragraph>
          Discover your protein transition: towards a future-proof diet. Within
          the application, users, can explore different protein transition
          scenarios. In the application, the user is seated at a regional dining
          table and chooses the food items or food pattern. The impact of user's
          choices is immediately visible through the visualization of the
          consequences on land use, greenhouse gas emissions, the water
          footprint and personalized protein requirements. In this way, the user
          will get a deeper understanding of the current global protein
          production, trade and consumption patterns, including indicational
          impact on environment and intake.
        </Paragraph>
        <Paragraph nextColumn>
          <img src={proteinTransitionImg} style={{ maxWidth: "100%" }} alt="" />
        </Paragraph>
      </ContentText>
    </MainContainer>
  );
};

export default ProjectPage;
