import { useState } from "react";
import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import { TabButton } from "../Buttons";
import Research from "./Research";
import Education from "./Education";
import Industry from "./Industry";

const tabs = [
  { label: "Research", component: Research },
  { label: "Education", component: Education },
  { label: "Industry", component: Industry },
];

const ContentContainer = styled.div`
  margin-top: 64px;
`;

const Sector = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabChange = (tab) => () => setActiveTab(tab);

  const TabContentComponent = activeTab.component;

  return (
    <div>
      <ContentWidthContainer>
        {tabs.map((tab) => {
          return (
            <TabButton
              key={tab.label}
              active={activeTab.label === tab.label}
              onClick={tabChange(tab)}
            >
              {tab.label}
            </TabButton>
          );
        })}
      </ContentWidthContainer>
      <ContentContainer>
        <TabContentComponent />
      </ContentContainer>
    </div>
  );
};

export default Sector;
