import styled, { css } from "styled-components";
import Tag from "./Tag";

const activeStates = {
  none: -1,
  inActive: 0,
  active: 1,
};

const fontStyles = {
  [activeStates.none]: css`
    font-style: normal;
  `,
  [activeStates.inActive]: css`
    font-style: italic;
  `,
  [activeStates.active]: css`
    font-weight: bold;
  `,
};

const Header = styled.div`
  font-style: italic;
`;

const TagContainer = styled.div`
  ${({ $state }) => fontStyles[$state]};
  cursor: pointer;
`;

const StyledButton = styled.span`
  font-size: 60%;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  line-height: 1em;
  text-align: center;
  display: inline-block;
  font-weight: normal;
`;

const CloseButton = () => <StyledButton>&times;</StyledButton>;

const Filters = ({ tags, onClickFilter, activeFilter }) => {
  if (!tags?.length) {
    return null;
  }
  return (
    <div>
      <Header>Filter</Header>
      {tags.map((tag) => {
        const state = activeFilter
          ? activeFilter === tag
            ? activeStates.active
            : activeStates.inActive
          : activeStates.none;
        return (
          <TagContainer
            onClick={() => onClickFilter(tag)}
            $state={state}
            key={tag.label}
          >
            <Tag color={tag.color} /> = {tag.label}{" "}
            {state === activeStates.active && <CloseButton />}
          </TagContainer>
        );
      })}
    </div>
  );
};

export default Filters;
