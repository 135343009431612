import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { ContentWidthContainer } from "../MainLayout";
import { TabLinkButton } from "../Buttons";

const tabs = [
  { label: "About WANDER", to: "about-wander" },
  { label: "How we work", to: "how-we-work" },
  {
    label: "Meet the crew",
    to: "meet-us",
  },
  { label: "Contact", to: "contact" },
];

const ContentContainer = styled.div`
  margin-top: 64px;
`;

const About = () => {
  return (
    <div>
      <ContentWidthContainer>
        {tabs.map((tab) => {
          return (
            <TabLinkButton key={tab.to} to={tab.to}>
              {tab.label}
            </TabLinkButton>
          );
        })}
      </ContentWidthContainer>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </div>
  );
};

export default About;
