import styled from "styled-components";
import { primaryColor } from "../../style/colors";

const MainContainer = styled.div`
  margin-top: 32px;
  line-height: 1.4;
`;

const Quote = styled.div`
  color: ${primaryColor};
  font-style: italic;
  margin-bottom: 0.2em;
`;

const Name = styled.div`
  font-style: italic;
  font-size: 80%;
`;

const Testimonial = ({ quote, name }) => {
  return (
    <MainContainer>
      <Quote>&ldquo;{quote}&rdquo;</Quote>
      {name && <Name>~{name}~</Name>}
    </MainContainer>
  );
};

export default Testimonial;
