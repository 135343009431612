import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import {
  BackgroundSvg,
  PrimaryLine,
  WhitePolygon,
  PrimaryPolygon,
  EmText,
  BlockHeader,
  ParagraphHeader,
  Paragraph,
  ColumnContainer,
  ContentColumn,
} from "../MainLayout/styled";
import {
  darkBlue,
  backgroundDark,
  backgroundLightBlue,
  secondaryColor,
} from "../../style/colors";

import backgroundStars from "../../assets/Wander_sterren_01.png";
import grid from "../../assets/Wander_Blok_patroon_02.svg";
import iPad from "../../assets/iPad_Pro_Wander_Mockup.png";

const MainContainer = styled.div`
  color: ${darkBlue};
`;

const HeadBlockContainer = styled.div``;
const BottomBlockContainer = styled.div`
  position: relative;
  color: #fff;
  padding-top: 150px;
  padding-bottom: 180px;
`;

const BackgroundContainer = styled.div`
  background: url(${backgroundStars}), url(${grid}),
    radial-gradient(
      circle at 0% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: repeat, repeat, no-repeat, no-repeat;
  background-size: cover, auto, cover, auto;
  /* background-position: 0px 400px; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%);
`;

const MainImage = styled.img.attrs({ src: iPad })`
  position: absolute;
  top: -220px;
  left: calc(50vw - 180px);
  width: 75vw;
  max-width: 850px;
`;

const NumberedList = styled.ol`
  padding-left: 48px;
  list-style: none;
  counter-reset: li;

  & li {
    margin-bottom: 32px;
    counter-increment: li;
    line-height: 1.5;
  }

  & li::before {
    font-size: 200%;
    font-weight: bold;
    content: counter(li);
    color: ${secondaryColor};
    display: inline-block;
    width: 32px;
    margin-left: -48px;
    margin-right: 16px;
    text-align: right;
    direction: rtl;
  }
`;
const Research = () => {
  return (
    <MainContainer>
      <HeadBlockContainer>
        <ContentWidthContainer>
          <BlockHeader>
            WANDER as part of your
            <br /> <EmText>research journey</EmText>
          </BlockHeader>
          <Paragraph>
            Research development often involves the collection and
            interpretation of complex datasets. Visualising and experiencing
            such data can positively contributes to its analysis and
            communication phase. WANDER-lab empowers you to make use of the most
            advanced 3D visualisation techniques such virtual reality (VR),
            augmented reality (AR) or Mixed Reality (MX) - to develop advanced
            data driven immersive applications- through your research journey.{" "}
          </Paragraph>
          <ColumnContainer>
            <ContentColumn>
              <ParagraphHeader>Position novel research</ParagraphHeader>
              <Paragraph>
                Combine virtual elements and merge them to the real world to
                discover and interact with hidden insights
              </Paragraph>

              <ParagraphHeader>
                Embedded within your research process
              </ParagraphHeader>
              <Paragraph>
                Generate new perspectives and scenarios based on immersive
                experiences
              </Paragraph>
            </ContentColumn>
            <ContentColumn>
              <ParagraphHeader>
                Communicate your research findings
              </ParagraphHeader>
              <Paragraph>
                Facilitate stakeholders to understand, learn and be able to act
                upon your findings
              </Paragraph>
            </ContentColumn>
          </ColumnContainer>
        </ContentWidthContainer>
      </HeadBlockContainer>

      <BottomBlockContainer>
        <BackgroundContainer>
          <BackgroundSvg $bottom style={{ height: 200 }}>
            <PrimaryLine x1="0" y1="40" x2="100" y2="100" />
            <PrimaryPolygon points="0,100 100,0 100,100" />
            <WhitePolygon points="0,100 37.5,62.5 100,100" />
          </BackgroundSvg>
        </BackgroundContainer>
        <MainImage />

        <ContentWidthContainer>
          <ColumnContainer>
            <ContentColumn>
              <BlockHeader>
                Project <EmText>Selection</EmText> Criteria
              </BlockHeader>
              <NumberedList>
                <li>The project involves collection & access to data</li>
                <li>
                  The project leader is ready to onboard on an iterative process
                </li>
                <li>
                  The involvement of the lab in the project should help to
                  develop new knowledge and understanding of new technologies
                  and how these can positively contribute to society matters.
                </li>
                <li>Cross-disciplinary projects is a plus</li>
              </NumberedList>
            </ContentColumn>
            <ContentColumn></ContentColumn>
          </ColumnContainer>
        </ContentWidthContainer>
      </BottomBlockContainer>
    </MainContainer>
  );
};

export default Research;
