import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import {
  BackgroundSvg,
  starGridPrimaryBackground,
  EmText,
  ContentColumn,
  BlockHeader,
  ParagraphHeader,
  Paragraph,
} from "../MainLayout/styled";
import { darkBlue, backgroundLight, primaryColor } from "../../style/colors";

import { Develop, Play, Together } from "../Icons";

const MainContainer = styled.div`
  color: ${darkBlue};
  position: relative;
  padding-bottom: 40px;
`;

const ContentBlock = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 200px;
  margin-bottom: 58px;

  @media (max-width: 768px) {
    display: block;
    text-align: center;
    max-width: 400px;
    margin: 60px auto 30px;
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  overflow: hidden;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%); */
`;

const TopBackground = styled.div`
  /* height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const LowerBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  background: ${backgroundLight};
  height: 1000px;
  left: 0;
  right: 0;
  /* z-index: -1; */
  border-top: 1px solid ${primaryColor};
  transform: skewY(-8deg);
  transform-origin: 100% 0;
  /* clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%); */
`;

const StarsBackground = styled(BackgroundSvg)`
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  height: 200px;
  mix-blend-mode: normal;
  ${starGridPrimaryBackground}
  clip-path: polygon(0% 100%, 100% 100%, 0 0);
`;

const iconStyle = { height: 150, width: "auto" };

const ColumnHeader = styled(ParagraphHeader)`
  color: inherit;
`;

const HowWeWork = () => {
  return (
    <MainContainer>
      <BackgroundContainer>
        <TopBackground>
          <StarsBackground />
        </TopBackground>

        <LowerBackgroundContainer></LowerBackgroundContainer>

        {/* <BackgroundShapes $bottom>
          <WhitePolygon points="0,0 100,100 0,100" />
          <PrimaryLine x1="0" y1="0" x2="100" y2="99" />
        </BackgroundShapes> */}
      </BackgroundContainer>

      <ContentWidthContainer>
        <BlockHeader>
          How we <EmText>work</EmText>
        </BlockHeader>

        <ContentBlock>
          <ContentColumn>
            <Together style={iconStyle} />
            <ColumnHeader>Together</ColumnHeader>
            <Paragraph>
              We explore together the added value of data visualisation in your
              specific research field.
            </Paragraph>
          </ContentColumn>
          <ContentColumn>
            <Play style={iconStyle} />
            <ColumnHeader>Discover</ColumnHeader>
            <Paragraph>
              We introduce you to cutting edge 3D visualisation techniques and
              existing use case to brainstorm and identify the potential for
              your field of expertise.
            </Paragraph>
          </ContentColumn>
          <ContentColumn>
            <Develop style={iconStyle} />
            <ColumnHeader>Develop</ColumnHeader>
            <Paragraph>
              Engage with us in an iterative process of interactive data-driven
              visualisations finalized into an 3D application
            </Paragraph>
          </ContentColumn>
        </ContentBlock>
      </ContentWidthContainer>
    </MainContainer>
  );
};

export default HowWeWork;
