import styled from "styled-components";

// 9/16 * 100 = 56,25
const ResponsiveContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const VideoEmbedResponsive = ({
  videoCode,
  title = "Video player",
  children,
  style,
  className,
  ...props
}) => {
  return (
    <ResponsiveContainer style={style} className={className}>
      <IFrame
        frameborder="0"
        allow="fullscreen"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        loading="lazy"
        title={title}
        {...props}
      />
    </ResponsiveContainer>
  );
};

export default VideoEmbedResponsive;
