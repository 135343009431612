import styled from "styled-components";

export const MainContainer = styled.div``;

export const VideoCaption = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
  line-height: 1.4;
`;

export const VideoCaptionDescription = styled.div`
  max-width: 800px;
  margin-top: 1em;
`;

export const ContentText = styled.div`
  column-count: 2;

  @media (max-width: 768px) {
    column-count: 1;

    & > * {
      break-before: auto;
    }
  }
`;

export const MainImage = styled.img`
  max-width: 100%;
`;
