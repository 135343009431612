import styled from "styled-components";
import PropTypes from "prop-types";
import { UnstyledLink } from "../Buttons";
import Tag from "./Tag";

const spacing = 32;

export const TileContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${spacing}px;
`;

const MainContainer = styled(UnstyledLink)`
  width: calc(33.33% - ${spacing}px);

  @media (max-width: 1000px) {
    width: calc(50% - ${spacing}px);
  }
`;

const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;

const TileImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Caption = styled.div`
  font-size: 80%;
`;
const Label = styled.div``;
const Description = styled.div``;

const ProjectTile = ({ project }) => {
  return (
    <MainContainer to={project.url}>
      <ImgContainer>
        <TileImg src={project.image} />
      </ImgContainer>
      <Caption>
        <Label>
          {project.tags &&
            project.tags.map((tag) => (
              <Tag color={tag.color} key={tag.label} />
            ))}

          {project.label}
        </Label>
        <Description>{project.description}</Description>
      </Caption>
    </MainContainer>
  );
};

ProjectTile.propTypes = {
  project: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
      })
    ),
  }),
};

export default ProjectTile;
