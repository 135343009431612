import styled from "styled-components";

const InnerContainer = styled.div`
  /* backdrop-filter: blur(3px); */
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
  transform-style: preserve-3d;
  transition-duration: 600ms;
  transition-property: transform;
  transform: rotateY(0deg);
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* ------- */
  background-color: #ffffff33;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ffffff33;
  border-left: 1px solid #ffffff33;
`;

const FrontSide = styled(CardFace)`
  transform: rotateY(0deg);
`;
const BackSide = styled(CardFace)`
  transform: rotateY(180deg);
`;

const MainContainer = styled.div`
  background-color: transparent;
  width: 300px;
  height: 200px;
  display: inline-block;
  margin: 16px;
  perspective: 1000px;

  &:hover ${InnerContainer} {
    transform: rotateY(180deg);
  }
`;

const FlipCard = ({ children }) => {
  const [front, back] = children;
  return (
    <MainContainer>
      <InnerContainer>
        <FrontSide>{front}</FrontSide>
        <BackSide>{back}</BackSide>
      </InnerContainer>
    </MainContainer>
  );
};

export default FlipCard;
