import { Fragment } from "react";
import styled from "styled-components";
import { EmText } from "../../MainLayout/styled";
import wurlogo from "../../../assets/Logo_WUR_wit.png";

import { MailButton } from "../../Buttons";

const ContentContainer = Fragment;

const LargeText = styled.div`
  font-size: 50px;
  max-width: 700px;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;
const WurLogo = styled.img.attrs({ src: wurlogo })`
  display: block;
  width: 200px;
`;

const MainBlock = () => {
  return (
    <ContentContainer>
      <LargeText>
        Turning intangible data into{" "}
        <EmText>3D visualisations and immersive</EmText> experiences
      </LargeText>
      <div>
        <MailButton>book a brainstorming session</MailButton>
      </div>
      <WurLogo />
    </ContentContainer>
  );
};
export default MainBlock;
