import styled from "styled-components";
import { EmText, Paragraph } from "../../MainLayout/styled";
import { LinkButton } from "../../Buttons";
// import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import VideoEmbedResponsive from "../../Media/VideoEmbedResponsive";
import treePollenImg from "../../Project/ProjectPages/treePollen.png";

const Main = styled.div``;

const ProjectTitle = styled.div`
  margin-bottom: 48px;
  font-size: 140%;
`;

const Button = styled(LinkButton)`
  /* display: block; */
  margin-top: 36px;
  margin-right: 36px;
`;

const TwoColumnLayout = styled.div`
  column-count: 2;
  margin-top: 56px;

  @media (max-width: 768px) {
    column-count: 1;
  }
`;

const WhatWeDoBlock = () => {
  return (
    <Main>
      <ProjectTitle>
        XR-<EmText>Project</EmText>
        <br />
        <b>Exploration:</b> Digital Twin Pollen at WUR Campus
      </ProjectTitle>
      {/* <YoutubeEmbed videoCode="jN1ANC4K5BQ">
        <img src={treePollenImg} alt="video still" />
      </YoutubeEmbed> */}
      <VideoEmbedResponsive
        title="Embedded Media titled: Wander Digital Twins"
        width="560"
        height="315"
        src="https://wur.yuja.com/V/Video?v=192544&node=821337&a=127750649&preload=false"
      >
        <img src={treePollenImg} alt="video still" />
      </VideoEmbedResponsive>
      <TwoColumnLayout>
        <Paragraph style={{ marginTop: 0 }}>
          Through cutting edge visualisation techniques, WANDER'S core team
          empowers WUR community & external stakeholders to use novel data
          driven visualisation tools which can better support the transition
          towards advanced methodologies, decision-making processes and
          outreach.
        </Paragraph>
      </TwoColumnLayout>
      <Button to="/about/about-wander">Learn more</Button>
      <Button to="xr-projects" variant="outlined">
        See all xr-projects
      </Button>
    </Main>
  );
};
export default WhatWeDoBlock;
