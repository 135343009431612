import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { ContentWidthContainer } from "./";
import { BackgroundSvg } from "./styled";
import { darkBlue } from "../../style/colors";
import Header from "../Header";
import Footer from "../Footer";
import StarrySky from "../StarrySky";
export { ContentWidthContainer };

const MainContainer = styled.div``;
const InnerContainer = styled.div`
  padding-top: calc(var(--header-height, 0) + 64px);
  color: ${darkBlue};
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
`;
const BackgroundTop = styled(BackgroundSvg)`
  height: calc(var(--header-height, 0) + 16px);
  mix-blend-mode: normal;
`;

const StyledStars = styled(StarrySky).attrs({ starCount: 100 })`
  height: calc(var(--header-height, 70) + 16px);
`;

const PageLayout2 = ({ footerBackground, ...props }) => {
  return (
    <MainContainer {...props}>
      <InnerContainer>
        <Header />
        <BackgroundTop></BackgroundTop>
        <StyledStars showNightBackground={true} showGrid />

        <Outlet />
      </InnerContainer>
      <Footer backgroundColor={footerBackground} />
    </MainContainer>
  );
};
export default PageLayout2;
