import {
  PageHeader,
  ParagraphHeader,
  Paragraph,
  EmText,
} from "../../MainLayout/styled";
import Testimonial from "../Testimonial";
import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import { MainContainer, VideoCaption, ContentText } from "../styled";
import projectImg from "./heartbeat.jpg";

const Heartbeat = () => {
  return (
    <MainContainer>
      <PageHeader>HeARtbeat</PageHeader>

      <YoutubeEmbed videoCode="Fva8JrFeLZk">
        <img src={projectImg} alt="" />
      </YoutubeEmbed>

      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>HeARtbeat:</b> Integrating AR for Physiology Learning
      </VideoCaption>

      <ContentText>
        <ParagraphHeader>Challenge - What do we see</ParagraphHeader>
        <Paragraph>
          The challenge was Design or integrate anatomical, physiological and
          molecular images and animations to assist in the teaching and learning
          of cardiac physiology. Specifically, the placement of ECG electrodes
          on the human body.
        </Paragraph>

        <ParagraphHeader>Objective</ParagraphHeader>
        <Paragraph>
          Technology will assist with the underpinning learning so that learning
          does not stop or is confined to when medical students are in the
          university only. This will facilitate the students' own pace of
          learning and competency.
        </Paragraph>
        <ol>
          <li>
            Design or integrate anatomical, physiological and molecular images
            and animations to assist in the teaching and learning of cardiac
            physiology.
          </li>
          <li>
            Using AR markers to label anatomical landmarks, enable guided
            placement of ECG electrode placement. Accuracy of placement and
            haptic feedback could assist in competency efficiency.{" "}
          </li>
        </ol>

        <ParagraphHeader>New insights</ParagraphHeader>
        <Paragraph>
          This app provides a learning opportunity to rehearse and perfect
          clinical skill training, on a personalised device, aligning to a
          student lead PACE of learning.
        </Paragraph>
        <Paragraph>
          The app will assist in establishing pedagogical research excellence in
          this area and explore efficiency of practical skill learning.
        </Paragraph>

        <ParagraphHeader>Future applications</ParagraphHeader>
        <Paragraph>
          The AR smartphone app will be a simulation tool for ALL health
          professionals and involve upskilling novices in ECG electrode
          placement prior to engaging with actual human patients in clinical
          practice. There is also a tangible outcome of improving patient safety
          in clinical environments, this AR smartphone app will offer quality
          assurances to experienced health professionals so that all patient
          encounters are accurate, mitigating human error due to body habitus.
          The incorporation of this AR smartphone app into research evaluating
          clinical skill acquisition through Technology Enhanced and Assisted
          Learning will enable an evaluation of how AR can upskill ECG electrode
          placement in novice practitioners.
        </Paragraph>

        <ParagraphHeader>Testimony</ParagraphHeader>
        <Paragraph>
          Wander have created a proof-of-concept that will allow us to test our
          theories with students in a higher education setting – the findings
          from the study will provide the foundations for us to apply for
          collaborative grant-funding opportunities with WUR to take this
          investigation forwards, leading to a positive impact on the way
          physiology learning takes place.
        </Paragraph>
        <Testimonial
          quote="Collaborating with the WANDER lab to build an augmented reality smartphone application has been a huge success.  The brief describing the AR app design and function were very readily interpreted and the end product is excellent.  At all times the collaboration with WANDER was extremely efficient with specialist staff allocation to this project from inception.  Working with WANDER is highly recommended and I look forward to future endeavours."
          name="Dr Cathal Breen, Ulster Uni School of Medicine"
        />
      </ContentText>
    </MainContainer>
  );
};

export default Heartbeat;
