import styled from "styled-components";
import { Link } from "react-router-dom";
import { ContentWidthContainer } from "../MainLayout";
import WanderLogo from "../WanderLogo";
import NavigationMenu from "../NavigationMenu";

const HeaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: ${(props) =>
    props.home
      ? "var(--header-height-home, auto)"
      : "var(--header-height, 70px)"};
  box-sizing: border-box;
  /* font-family: "Gimbal";
  font-size: 10px; */
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.2em;
  z-index: 10;
`;

const InnerContainer = styled(ContentWidthContainer)`
  position: relative;
  height: 100%;
`;

const HomeLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: 0;
`;

const NavigationMenuContainer = styled.div`
  float: right;
  height: 100%;
  width: calc(100% - 200px);
`;

const Header = ({ home = false }) => {
  return (
    <HeaderContainer home={home}>
      <InnerContainer>
        <HomeLink to="/">
          <WanderLogo style={{ width: 200, color: "#fff" }} />
        </HomeLink>
        <NavigationMenuContainer>
          <NavigationMenu
            items={[
              { to: "/", label: "Home" },
              { to: "/xr-projects", label: "XR-projects" },
              { to: "/sector", label: "Sector" },
              { to: "/about", label: "About WANDER" },
            ]}
          />
        </NavigationMenuContainer>
      </InnerContainer>
    </HeaderContainer>
  );
};

export default Header;
