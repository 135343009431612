import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import PageLayout from "./components/MainLayout/PageLayout";
import PageLayout2 from "./components/MainLayout/PageLayout2";
import HomeView from "./views/Home";
import ProjectsView from "./views/Projects";
import ProjectView from "./views/Project";
import SectorView from "./views/Sector";
import AboutView from "./views/About";
import AboutWander from "./components/About/About";
import HowWeWork from "./components/About/HowWeWork";
import MeetUs from "./components/About/MeetUs";
import Contact from "./components/About/Contact";

import NoMatch from "./views/NoMatch";
import Demo from "./components/Demo";
import "./style/fonts.css";
import "./style/variables.css";
import "./style/App.css";
import projects from "./components/Projects/projects";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/" element={<PageLayout />}>
          <Route path="xr-projects" element={<Outlet />}>
            <Route index element={<ProjectsView />} />
            <Route path="" element={<ProjectView />}>
              {projects.map((project) => {
                const Content = project.component;
                return (
                  <Route
                    key={project.url}
                    path={project.url}
                    element={
                      Content ? (
                        <Content project={project} />
                      ) : (
                        <div>Project not found</div>
                      )
                    }
                  />
                );
              })}
            </Route>
          </Route>
        </Route>

        <Route path="/" element={<PageLayout2 footerBackground="#fff" />}>
          <Route path="sector" element={<SectorView />} />
        </Route>

        <Route path="/" element={<PageLayout2 />}>
          <Route path="about" element={<AboutView />}>
            {/* <Route index element={<AboutWander />} /> */}
            <Route index element={<Navigate to="about-wander" />} />
            <Route path="about-wander" element={<AboutWander />} />
            <Route path="how-we-work" element={<HowWeWork />} />
            <Route path="meet-us" element={<MeetUs />} />
            <Route path="contact" element={<Contact />} />
          </Route>
        </Route>

        <Route path="demo" element={<Demo />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}

export default App;
