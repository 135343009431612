import { useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Block1 from "./BlockBackground/Block1";
import Block2 from "./BlockBackground/Block2";
import Block3 from "./BlockBackground/Block3";
import Block4 from "./BlockBackground/Block4";
import MainBlock from "./BlockContent/Main";
import WhatWeDo from "./BlockContent/WhatWeDo";
import OfferingBlock from "./BlockContent/Offering";
import TechnologiesBlock from "./BlockContent/Technologies";

const Home = () => {
  const block2Ref = useRef();

  const scrollToSection = (ref) => {
    if (ref?.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  return (
    <div>
      <Header home />
      <Block1
        scrollToNext={() => {
          scrollToSection(block2Ref);
        }}
      >
        <MainBlock />
      </Block1>
      <Block2 ref={block2Ref}>
        <WhatWeDo />
      </Block2>
      <Block3>
        <OfferingBlock />
      </Block3>
      <Block4>
        <TechnologiesBlock />
      </Block4>
      <Footer backgroundColor="#fff" />
    </div>
  );
};

export default Home;
