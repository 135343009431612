/* 
This function makes sure we land at the top of the page when we navigate. 
Otherwise we would end up at the same scroll level as we were on the page where 
we clicked to navigate
source: https://v5.reactrouter.com/web/guides/scroll-restoration
see also: https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
*/
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
