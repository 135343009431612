// import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const round = (num, dec = 5) =>
  Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);

const tailAnimation = (tailWidth) => keyframes`
    0% {width: 0;}
    30% {width: ${tailWidth}px}
    70% {width: ${tailWidth}px}
    100%{width: 0;}
`;
const containerAnimation = (startPosition, direction, tailWidth) => {
  const radDirection = -direction * 2 * Math.PI;
  return keyframes`
    0% {
      top: ${startPosition[0]}%;
      right: ${100 - startPosition[1]}%; 
    }
    100% {
      top: calc(${startPosition[0]}% - ${
    10 * tailWidth * round(Math.sin(radDirection))
  }px);
      right: calc(${100 - startPosition[1]}% - ${
    10 * tailWidth * round(Math.cos(radDirection))
  }px); 
  }
`;
};
const opacityAnimation = keyframes`
    0% {opacity: 0}
    20% {opacity: 1}
    80% {opacity: 1}
    100%{opacity: 0}
`;

const StyledContainer = styled.div`
  position: absolute;
  top: ${({ $startPosition }) => $startPosition[0]}%;
  right: ${({ $startPosition }) => 100 - $startPosition[1]}%;
  height: ${({ $size }) => $size}px;
  opacity: 0;
  animation-name: ${({ $startPosition, $direction, $tailWidth }) =>
      containerAnimation($startPosition, $direction, $tailWidth)},
    ${opacityAnimation};
  animation-duration: ${({ $duration }) => $duration}ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  transform: ${({ $direction }) => `rotate(${$direction}turn)`};
`;

const Star = styled.div`
  position: absolute;
  right: 0;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  background-color: white;
`;

const Tail = styled.div`
  width: 0px;
  right: 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 2}px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  animation-name: ${({ $tailWidth }) => tailAnimation($tailWidth)};
  animation-duration: ${({ $duration }) => $duration}ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`;

const ShootingStar = ({
  size = 1,
  startPosition = [20, 10],
  direction = 0.2,
  duration = 1000, //ms
  length = 500,
}) => {
  const stylingProps = {
    $size: size,
    // $duration: (1000 * length) / speed,
    $duration: duration,
    $tailWidth: length / 10,
    $startPosition: startPosition,
    $direction: direction,
  };
  return (
    <StyledContainer {...stylingProps}>
      <Star {...stylingProps} />
      <Tail {...stylingProps} />
    </StyledContainer>
  );
};

ShootingStar.propTypes = {
  size: PropTypes.number, //px default 1
  startPosition: PropTypes.arrayOf(PropTypes.number), // % [top, left]
  direction: PropTypes.number, // turns 0 -> 1
  length: PropTypes.number, // px,
  duration: PropTypes.number, // ms
};
export default ShootingStar;
