import styled from "styled-components";

const StyledSvg = styled.svg`
  /* transform-style: preserve-3d;
  transition-duration: 600ms;
  transition-property: transform;
  transform: rotateY(0deg);

  &:hover {
    transform: rotateY(10deg);
  } */
`;

const StyledPath = styled.path`
  fill-opacity: 0;
  stroke-width: 1px;
  stroke: #007c00;
  /* filter: url(#glow); */
  filter: ${({ $glow }) => ($glow ? `url(#glow)` : `none`)};
`;

const SvgGlow = ({ glow = true, ve = false }) => {
  return (
    <StyledSvg viewBox="0 0 440 140">
      <defs>
        <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur
            result="coloredBlur"
            stdDeviation="6"
            id="blur1"
          ></feGaussianBlur>
          <feMerge>
            <feMergeNode in="coloredBlur"></feMergeNode>
            <feMergeNode in="coloredBlur"></feMergeNode>
            <feMergeNode in="coloredBlur"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g transform="translate(20,20)">
        <StyledPath
          id="headset"
          // d="M 94,11 0,15 M 95,25 V 11 L 135,2 M 0,28 95,25 135,17 M 45,1 0,15 0.5,28 45,22 M 45,0.80 45,22 135,17 V 1.8 Z"
          d="M276 42 2 54M279 83V42L395 16M2 91 279 83 395 59M133 13 2 54 3 91 133 74M133 12V74L395 59V15Z"
          pointerEvents="bounding-box"
          // vectorEffect="non-scaling-stroke"
          $glow={glow}
        >
          {/* <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 60 70"
            to="360 60 70"
            dur="10s"
            repeatCount="indefinite"
          /> */}
        </StyledPath>
      </g>
      {/* <animate
        href="#blur1"
        attributeName="stdDeviation"
        // values="0;3"
        from="0"
        to="4"
        fill="freeze"
        dur="500ms"
        // begin="0s"
        begin="headset.mouseenter"
        repeatCount="1"
      />
      <animate
        href="#blur1"
        attributeName="stdDeviation"
        from="4"
        to="0"
        fill="freeze"
        dur="500ms"
        // begin="0s"
        begin="headset.mouseout"
        repeatCount="1"
      /> */}
    </StyledSvg>
  );
};

export default SvgGlow;
