import styled, { keyframes } from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import {
  BackgroundSvg,
  starGridBackground,
  EmText,
  BlockHeader,
  ParagraphHeader,
  Paragraph,
} from "../MainLayout/styled";
import { darkBlue, backgroundLight, primaryColor } from "../../style/colors";
import robotImg from "../../assets/robot.png";
import { MailButton } from "../Buttons";

const MainContainer = styled.div`
  color: ${darkBlue};
  position: relative;
  padding-bottom: 23vw;
`;

const ContentBlock = styled.div`
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LowerContentBlock = styled(ContentBlock)`
  position: absolute;
  bottom: max(15vw - 100px, 0px);

  @media (max-width: 768px) {
    width: 100%;
    width: calc(100% - 32px - 8vw);
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: auto;
  height: ${612 + 100 + 13}px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  overflow: hidden;

  @media (max-width: 768px) {
    opacity: 0.5;
  }
`;

const LowerBackgroundShape = styled.div`
  position: absolute;
  top: 23vw;
  background: ${backgroundLight};
  height: 800px;
  left: 0;
  right: 0;
  /* z-index: -1; */
  border-top: 1px solid ${primaryColor};
  transform: skewY(10deg);
  transform-origin: 100% 0;
  /* clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%); */
`;
const LowerBackgroundContainer = styled.div`
  position: absolute;
  height: 28vw;
  width: 100%;
  bottom: 0;
  overflow: hidden;
`;

const StarsBackground = styled(BackgroundSvg)`
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0px;
  height: 200px;
  mix-blend-mode: normal;
  ${starGridBackground}
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
`;

const RobotContainer = styled.div`
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 50%;
  height: 100%;

  /* @media (max-width: 1200px) {
    right: auto;
    left: 0vh;
    width: 100vw;
    top: 100px;
  }*/

  @media (max-width: 768px) {
    /* right: auto;
    left: 30vh;
    width: 100vw;
    top: 100px; */
    bottom: -100px;
  }
`;
const Robot = styled.img.attrs({ src: robotImg })`
  position: absolute;
  /* height: 63vh; */
  top: 13px;
  left: 10px;
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
const FingerTipContainer = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  height: 150px;
  width: 150px;
`;

const GreenCircle = styled.div.attrs((props) => ({
  style: {
    animationDelay: `${props.i * 200}ms`,
  },
}))`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: ${({ $size }) => $size || 0}%;
  height: ${({ $size }) => $size || 0}%;
  border: 1px solid green;
  border-radius: 50%;
  ${({ $back }) => $back && `background: rgba(255,255,255,0.4);`}
  animation: ${fadeOut} 1.5s linear infinite;
`;

const BookButton = styled(MailButton)``;

const AboutWander = () => {
  return (
    <MainContainer>
      <BackgroundContainer>
        <StarsBackground />
        <RobotContainer>
          <Robot />
          <FingerTipContainer>
            <GreenCircle $size={100} i={5} $back />
            <GreenCircle $size={80} i={4} />
            <GreenCircle $size={65} i={3} />
            <GreenCircle $size={50} i={2} />
            <GreenCircle $size={30} i={1} />
            <GreenCircle $size={0} i={0} />
          </FingerTipContainer>
        </RobotContainer>

        <LowerBackgroundContainer>
          <LowerBackgroundShape />
        </LowerBackgroundContainer>

        {/* <BackgroundShapes $bottom>
          <WhitePolygon points="0,0 100,100 0,100" />
          <PrimaryLine x1="0" y1="0" x2="100" y2="99" />
        </BackgroundShapes> */}
      </BackgroundContainer>

      <ContentWidthContainer>
        <BlockHeader style={{ marginBottom: 40 }}>
          About <EmText>WANDER</EmText>
        </BlockHeader>

        <ContentBlock>
          <Paragraph>
            WANDER will form a WUR-wide hub for visualising and developing
            research further while engaging stakeholders, students and
            researchers to find solutions together. As a Shared Research and
            Education facility, we seek to provide a space where WUR community
            foster interdisciplinary research, education and external engagement
            through advanced data driven 3D visualisations and simulations.
          </Paragraph>

          <ParagraphHeader>Purpose</ParagraphHeader>
          <Paragraph>
            WANDER's main goal is to develop a <EmText>sharing</EmText> facility
            to advance science through 3D visualisation and immersive
            experiences in combination with modern data science to transform
            research, education, and outreach of the WUR community.
          </Paragraph>

          <ParagraphHeader>Vision</ParagraphHeader>
          <Paragraph>
            Our ambition is to become world leading in the scientific use and
            application of immersive visualisation in our domain of food and
            living environment.
          </Paragraph>
        </ContentBlock>

        <LowerContentBlock>
          <BookButton>book a brainstorming session</BookButton>
        </LowerContentBlock>
      </ContentWidthContainer>
    </MainContainer>
  );
};

export default AboutWander;
