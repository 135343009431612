import styled from "styled-components";
import PropTypes from "prop-types";
import { UnstyledLink } from "../Buttons";

const List = styled.ul`
  padding-left: 16px;
  margin-top: 32px;
  margin-bottom: 32px;

  & li {
    margin-bottom: 0.5em;
  }
`;

const ListItem = styled.li``;

const ProjectList = ({ projects = [] }) => {
  return (
    <List>
      {projects.map((project) => {
        return (
          <ListItem key={project.label}>
            <UnstyledLink to={project.url}>{project.label}</UnstyledLink>
          </ListItem>
        );
      })}
    </List>
  );
};

ProjectList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default ProjectList;
