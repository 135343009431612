import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import {
  BackgroundSvg,
  PrimaryLine,
  PrimaryPolygon,
  starGridBackground,
  EmText,
  ContentColumn,
  BlockHeader,
  Paragraph,
  ParagraphHeader,
} from "../MainLayout/styled";
import { MailButton } from "../Buttons";
import VRGlasses from "../../assets/VR-glasses.png";

const MainContainer = styled.div`
  color: #ffffff;
  position: relative;
  padding-top: 120px;
  padding-bottom: 180px;
`;

const ContentRow = styled.div`
  display: flex;
  gap: 32px;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  overflow: hidden;
  clip-path: polygon(
    0% 80px,
    100% 0%,
    100% 100%,
    42.86% calc(100% - 73.14px),
    0 100%
  );
`;

const RestyledPHeader = styled(ParagraphHeader)`
  margin-bottom: 0.25em;
`;
const RestyledP = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: 2em;
`;

const StarsBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${starGridBackground}
  clip-path: polygon(0% 80px, 100% 0%, 100% 100%, 0 100%);
`;

const BackgroundShapes = styled(BackgroundSvg)`
  height: 160px;
`;

const VRGlassesImg = styled.img.attrs({ src: VRGlasses, alt: "VR-glasses" })`
  width: 100%;
`;

const MailLink = styled.a`
  color: inherit;
`;

const Contact = () => {
  return (
    <MainContainer>
      <BackgroundContainer>
        <StarsBackground />

        <BackgroundShapes $bottom>
          <PrimaryLine x1="0" y1="95" x2="100" y2="0" />
          <PrimaryPolygon points="0,20 100,100 0,100" />
        </BackgroundShapes>
      </BackgroundContainer>

      <ContentWidthContainer>
        <ContentRow>
          <ContentColumn>
            <BlockHeader>
              <EmText>Contact</EmText>
            </BlockHeader>

            <RestyledP>
              Weather you want to learn more about immersive technologies such
              as AR & VR or explore how your data could turn into an advanced
              visualisation experience, we are here to help you through the
              journey.
            </RestyledP>
            <RestyledPHeader>Contact</RestyledPHeader>
            <RestyledP>
              <MailLink href="mailto:wander@wur.nl">wander@wur.nl</MailLink>
            </RestyledP>
            <RestyledPHeader>Location</RestyledPHeader>
            <RestyledP>
              Wageningen University & Research
              <br />
              Droevendaalsesteeg 3, 6708 PB
              <br />
              Wageningen
              <br />
              <br />
              The Netherlands
            </RestyledP>
          </ContentColumn>
          <ContentColumn>
            <VRGlassesImg />
          </ContentColumn>
        </ContentRow>
        <ContentRow style={{ marginTop: 32 }}>
          <MailButton>Send us an email</MailButton>
        </ContentRow>
      </ContentWidthContainer>
    </MainContainer>
  );
};

export default Contact;
