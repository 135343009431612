import styled, { css } from "styled-components";
import { secondaryColor } from "../../style/colors";

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
const openStyle = css`
  & span {
    opacity: 1;
    transform: rotate(45deg);
    background: ${secondaryColor};
  }

  /*
  * But let's hide the middle one.
  */
  & span:nth-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
  * Ohyeah and the last one should go the other direction
  */
  & span:nth-child(3) {
    transform: rotate(-45deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 27px;

  & span {
    display: block;
    width: 100%;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #fff;
    border-radius: 3px;

    z-index: 11;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  & span:first-child {
    transform-origin: 0% 0%;
  }

  & span:nth-child(3) {
    transform-origin: 0% 100%;
  }

  ${({ open }) => open && openStyle}
`;

const MenuToggle = ({ open = false, onToggle, ...props }) => {
  return (
    <Wrapper open={open} onClick={onToggle} {...props}>
      <span></span>
      <span></span>
      <span></span>
    </Wrapper>
  );
};

export default MenuToggle;
