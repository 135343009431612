import styled from "styled-components";
import { ReactComponent as LogoSvg } from "../assets/Wander_Logo.svg";


export const Logo = styled(LogoSvg)`
  width: ${(props) => typeof props.width === 'number' ? `${props.width}px` : props.width};
  color: ${props=>props.color};
`;

const WanderLogo = ({color = "#fff", width = 200, ...props})=>{
    return <Logo width={width} color={color} {...props} />;
}

export default WanderLogo;