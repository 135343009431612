import {
  PageHeader,
  ParagraphHeader,
  Paragraph,
  EmText,
} from "../../MainLayout/styled";
import Testimonial from "../Testimonial";
import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import { MainContainer, VideoCaption, ContentText } from "../styled";
import schoklandImg from "./schokland.png";

const ProjectPage = () => {
  return (
    <MainContainer>
      <PageHeader>Schokland</PageHeader>
      <YoutubeEmbed videoCode="hm8iHzoIgBc">
        <img src={schoklandImg} alt="" />
      </YoutubeEmbed>
      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Schokland:</b> World Heritage Site in Mixed Reality
      </VideoCaption>

      <ContentText>
        <ParagraphHeader>Challenge - What do we see</ParagraphHeader>
        <Paragraph>
          Schokland is a former island in the Zuiderzee (reclaimed in 1942),
          which has a Unesco World Heritage status. We have created a Mixed
          Reality (MR) application based on interdisciplinary scientific data,
          which enables the user to experience the past character of the island
          and to see how it developed from 1300 onwards.
        </Paragraph>

        <ParagraphHeader>Objective</ParagraphHeader>
        <Paragraph>
          Schokland has been inhabited for thousands of years, and has become a
          Dutch icon for the battle against the water. However, the present-day
          landscape only offers a faint glimpse of that past. The Mixed Reality
          app aims to make the past alive, as realistically as possible.
        </Paragraph>

        <ParagraphHeader>New insights</ParagraphHeader>
        <Paragraph>
          The collected scientific data proved to form valuable input for the
          Mixed Reality (MR) app. They show how people lived in small villages
          on dwelling mounds, on different parts of the island, and how the
          island got smaller over time due to sea level rise.
        </Paragraph>

        <ParagraphHeader>Future applications</ParagraphHeader>
        <Paragraph>
          The Mixed Reality (MR) app consists of a mobile installation that can
          be used in various places. This for example applies to the museum at
          Schokland, to other stakeholders involved or interested in the project
          (e.g. Het Flevo-landschap), and to academic events organised at the
          university – or any other relevant event.
        </Paragraph>

        <ParagraphHeader>Testimony</ParagraphHeader>
        <Testimonial
          quote="The Schokland MR app is a great tool for visualising scientific data
          and raising awareness and enthusiasm on landscape history. It builds a
          bridge between science and the wider audience, which we feel is highly
          important. In our experience, local stakeholders should be involved in
          the development process right from the start. Interestingly, the
          process of collecting and providing data for the app also raised more
          awareness of what we do not know yet, and still needs more scientific
          attention."
          name="Roy van Beek, Assistant Professor"
        />
      </ContentText>
    </MainContainer>
  );
};

export default ProjectPage;
