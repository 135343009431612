import styled from "styled-components";

const TagDiv = styled.div`
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  margin-right: 6px;
  background: ${({ $color }) => $color || "#000"};
`;

const Tag = ({ color }) => {
  return <TagDiv $color={color} />;
};

export default Tag;
