import styled, { css } from "styled-components";
// import { ReactComponent as Data3DSvg } from "../assets/svgIcons/Icon__3D_Data.svg";
import { ReactComponent as AISvg } from "../assets/svgIcons/Icon_AI.svg";
import { ReactComponent as ARSvg } from "../assets/svgIcons/Icon_AR.svg";
import { ReactComponent as AudioReactiveSvg } from "../assets/svgIcons/Icon_Audio_Reactive.svg";
// import { ReactComponent as CollaborativeSvg } from "../assets/svgIcons/Icon_Collaborative.svg";
import { ReactComponent as DataVisualisationSvg } from "../assets/svgIcons/Icon_Data_Visualisation.svg";
import { ReactComponent as DisplaysSvg } from "../assets/svgIcons/Icon_Displays.svg";
import { ReactComponent as GestureSvg } from "../assets/svgIcons/Icon_Gesture.svg";
import { ReactComponent as HologramsSvg } from "../assets/svgIcons/Icon_Holograms.svg";
// import { ReactComponent as InteractiveToolsSvg } from "../assets/svgIcons/Icon_Interactive_Tools.svg";
import { ReactComponent as ProjectionSvg } from "../assets/svgIcons/Icon_Projection.svg";
import { ReactComponent as VirtualSpacesSvg } from "../assets/svgIcons/Icon_Virtual_Spaces.svg";
import { ReactComponent as VRSvg } from "../assets/svgIcons/Icon_VR.svg";

// import { ReactComponent as DevelopSvg } from "../assets/svgIcons/Icon_Develop.svg";
// import { ReactComponent as PlaySvg } from "../assets/svgIcons/Icon_Play.svg";
// import { ReactComponent as TogetherSvg } from "../assets/svgIcons/Icon_Together.svg";

import { ReactComponent as ArrowDownSvg } from "../assets/Arrow_Down.svg";

import data3Dsrc from "../assets/animated/Icon__3D_Data_Anim-01.gif";
import interactiveToolsSrc from "../assets/animated/Icon_Interactive_Tools_Anim-01.gif";
import collaborativeSrc from "../assets/animated/Icon_Collaborative_Anim-01.gif";
import developSrc from "../assets/animated/Icon_Develop_animation.gif";
import playSrc from "../assets/animated/Icon_Play_animation-01.gif";
import togetherSrc from "../assets/animated/Icon_Together_animation.gif";

import { ReactComponent as ReplaySvg } from "../assets/svgIcons/replay-icon.svg";

const styles = css`
  width: ${(props) => props.size || 200}px;
`;

// export const Data3D = styled(Data3DSvg)`
//   ${styles}
// `;
export const Data3D = styled.img.attrs({ src: data3Dsrc })`
  ${styles}
`;
export const AI = styled(AISvg)`
  ${styles}
`;
export const AR = styled(ARSvg)`
  ${styles}
`;
export const AudioReactive = styled(AudioReactiveSvg)`
  ${styles}
`;
// export const Collaborative = styled(CollaborativeSvg)`
//   ${styles}
// `;

export const Collaborative = styled.img.attrs({ src: collaborativeSrc })`
  ${styles}
`;
export const DataVisualisation = styled(DataVisualisationSvg)`
  ${styles}
`;
export const Displays = styled(DisplaysSvg)`
  ${styles}
`;
export const Gesture = styled(GestureSvg)`
  ${styles}
`;
export const Holograms = styled(HologramsSvg)`
  ${styles}
`;
// export const InteractiveTools = styled(InteractiveToolsSvg)`
//   ${styles}
// `;

export const InteractiveTools = styled.img.attrs({ src: interactiveToolsSrc })`
  ${styles}
`;
export const Projection = styled(ProjectionSvg)`
  ${styles}
`;
export const VirtualSpaces = styled(VirtualSpacesSvg)`
  ${styles}
`;
export const VR = styled(VRSvg)`
  ${styles}
`;

export const ArrowDown = styled(ArrowDownSvg)`
  ${styles}
`;

// export const Develop = styled(DevelopSvg)`
//   ${styles}
// `;
// export const Play = styled(PlaySvg)`
//   ${styles}
// `;
// export const Together = styled(TogetherSvg)`
//   ${styles}
// `;
export const Develop = styled.img.attrs({ src: developSrc })`
  ${styles}
`;
export const Play = styled.img.attrs({ src: playSrc })`
  ${styles}
`;
export const Together = styled.img.attrs({ src: togetherSrc })`
  ${styles}
`;

export const Replay = styled(ReplaySvg)`
  ${styles}
`;
