import { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import MenuToggle from "./MenuToggle";
import { secondaryColor } from "../../style/colors";

const StyledNavLink = styled(NavLink).attrs({ end: true })`
  margin-left: 48px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  &.active {
    color: ${secondaryColor};
    border-bottom: 2px solid ${secondaryColor};
  }

  @media (max-width: 768px) {
    display: block;
    color: #000;
  }
`;

const NavigationMainContainer = styled.div`
  position: relative;
  height: 100%;

  @media (max-width: 768px) {
    position: ${({ mobileMenuOpen }) =>
      mobileMenuOpen ? `fixed` : `absolute`};
    top: 16px;
    right: 16px;
  }
`;

const NavigationContainer = styled.nav.attrs({ role: "navigation" })`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 768px) {
    position: fixed;
    top: 0px;
    bottom: auto;
    right: 0px;
    left: 0px;
    padding-left: 16px;
    padding-bottom: 32px;
    padding-top: 46px;
    padding-right: 46px;
    background: #fff;
    z-index: 10;

    transform-origin: calc(100% - 26px) 26px;
    /* transform: translate(100%, -100%); */
    transform: scale(0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    ${({ mobileMenuOpen }) => mobileMenuOpen && `transform: none;`}
  }
`;

const StyledMenuToggle = styled(MenuToggle)`
  display: none;

  @media (max-width: 768px) {
    display: block;
    top: 0;
    right: 0px;
  }
`;

const NavigationMenu = ({ items, ...props }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <NavigationMainContainer mobileMenuOpen={mobileMenuOpen} {...props}>
      <StyledMenuToggle
        open={mobileMenuOpen}
        onToggle={() => setMobileMenuOpen((prev) => !prev)}
      />
      <NavigationContainer mobileMenuOpen={mobileMenuOpen}>
        {items.map((navItem) => (
          <StyledNavLink to={navItem.to} key={navItem.label}>
            {navItem.label}
          </StyledNavLink>
        ))}
      </NavigationContainer>
    </NavigationMainContainer>
  );
};

export default NavigationMenu;
