import styled from "styled-components";
import { ContentWidthContainer } from "../MainLayout";
import {
  BackgroundSvg,
  PrimaryLine,
  WhitePolygon,
  EmText,
  BlockHeader,
  Paragraph,
} from "../MainLayout/styled";
import { darkBlue } from "../../style/colors";
import { MailButton } from "../Buttons";

const MainContainer = styled.div`
  color: ${darkBlue};
  position: relative;
  padding-bottom: 180px;
  min-height: 50vh;
`;

const ContentBlock = styled.div`
  width: 50%;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const BackgroundShapes = styled(BackgroundSvg)`
  mix-blend-mode: normal;
  height: 200px;
`;

const Industry = () => {
  return (
    <MainContainer>
      <BackgroundContainer>
        <BackgroundShapes $bottom>
          <WhitePolygon points="0,0 100,100 0,100" />
          <PrimaryLine x1="0" y1="0" x2="100" y2="99" />
        </BackgroundShapes>
      </BackgroundContainer>

      <ContentWidthContainer>
        <BlockHeader style={{ marginBottom: 80 }}>
          <EmText>Industry</EmText>
        </BlockHeader>

        <ContentBlock>
          <Paragraph>
            We are still working on the content of this page, it will be online
            soon...
          </Paragraph>
          <Paragraph>
            If you have a burning question for us in the meantime, please don't
            hesitate to:
          </Paragraph>
          <MailButton>Contact us</MailButton>
        </ContentBlock>
      </ContentWidthContainer>
    </MainContainer>
  );
};

export default Industry;
