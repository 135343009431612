import ReactTooltip from "react-tooltip";
import {
  PageHeader,
  // ParagraphHeader,
  // Paragraph,
  EmText,
} from "../../MainLayout/styled";
// import Testimonial from "../Testimonial";
import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import {
  MainContainer,
  VideoCaption,
  VideoCaptionDescription,
  //  ContentText,
} from "../styled";
import projectImg from "./auroraCanteen.png";
import { ExternalLink } from "../../MainLayout/styled";

const ProjectPage = () => {
  return (
    <MainContainer>
      <PageHeader>Digital Twin - Aurora Canteen</PageHeader>

      <YoutubeEmbed videoCode="UwSOw0z0bM4">
        <img src={projectImg} alt="" />
      </YoutubeEmbed>

      <ReactTooltip type="info" />

      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Digital Twin - Aurora Canteen</b>
        <VideoCaptionDescription>
          <p>
            We consider the VR representation of the Aurora catering facility as
            a first step in the further development of a “digital twin” for the
            restaurant and other nutrition/food related research. The ultimate
            aim of the “digital twin” is four-fold:
          </p>
          <ol>
            <li>
              To advance current research methods to explore innovative research
              questions that advance current research methods, for example by
              including social interaction or personalized/tailored assortments
            </li>
            <li>
              To develop a research infrastructure for validation studies on
              validity and reliability of VR studies as a basis for further
              refinement of VR/XR research infrastructure
            </li>
            <li>
              To develop a tool for implementation and education. The output can
              be used to feed decision making on healthy and sustainable
              contexts and is relevant to various education programs in
              different science groups
            </li>
            <li>
              To (co-)develop advanced visualization techniques and immersive
              experiences not only of the environment, but also on impact
              simulation (e.g. waste) and spatial-temporal pattern recognition
              (e.g. eye gaze data)
            </li>
          </ol>
          <p>
            For more information, get in touch with{" "}
            <ExternalLink href="https://www.wur.nl/en/Persons/Alexander-prof.dr.-AKAJ-Alexander-Klippel.htm">
              Alexander Klippel
            </ExternalLink>
            , Chair at the Laboratory of GIS and Remote Sensing.
          </p>
        </VideoCaptionDescription>
      </VideoCaption>
      {/* 
      <ContentText>
        <ParagraphHeader>Paragraph1</ParagraphHeader>
        <Paragraph>LoremIpsum</Paragraph>
      </ContentText> */}
    </MainContainer>
  );
};

export default ProjectPage;
