import { forwardRef } from "react";
import styled from "styled-components";
import { ContentWidthContainer } from "../../MainLayout";
import {
  HomeBlock,
  BackgroundSvg,
  PrimaryLine,
  LightPolygon,
  WhitePolygon,
} from "../../MainLayout/styled";
import { backgroundLight, darkBlue } from "../../../style/colors";
import StarrySky from "../../StarrySky";

const BlockContainer = styled(HomeBlock)`
  padding-top: 92px;
  background: ${backgroundLight};
  color: ${darkBlue};
`;
const BackgroundTop = styled(BackgroundSvg)`
  height: 100px;
  mix-blend-mode: normal;
`;

const StyledStars = styled(StarrySky).attrs({ starCount: 100 })`
  height: 99px;
  overflow: hidden;
  top: 1px;
`;

const ContentContainer = styled.div``;

const Block3 = forwardRef(({ children, ...props }, ref) => {
  return (
    <BlockContainer ref={ref} {...props}>
      <StyledStars showNightBackground={true} showGrid />
      <BackgroundTop>
        <WhitePolygon points="0,0 50,50 100,0" />
        <LightPolygon
          points="0,0 100,100 0,100"
          style={{
            stroke: backgroundLight,
          }}
        />
        <PrimaryLine
          style={{ stroke: darkBlue }}
          x1="0"
          y1="100"
          x2="100"
          y2="0"
        />
      </BackgroundTop>

      <ContentWidthContainer>
        <ContentContainer>{children}</ContentContainer>
      </ContentWidthContainer>
    </BlockContainer>
  );
});
export default Block3;
