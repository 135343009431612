import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { primaryColor } from "../style/colors";
import { transparentize } from "polished";

const StyledButton = styled.button.attrs({ type: "button" })`
  text-transform: uppercase;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  letter-spacing: 0.4em;
  cursor: pointer;
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.25);
  transition: all 200ms;

  &:active {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    transform: translate(4px, 4px) scale(0.95);
  }
`;

const containedButtonStyle = css`
  background: ${({ $color }) => $color};
  color: #ffffff;

  &:hover {
    /* filter: brightness(115%); */
    opacity: 0.75;
  }
`;

const ContainedButton = styled(StyledButton)`
  ${containedButtonStyle}
`;

const outlinedButtonStyle = css`
  background: transparent;
  border: 2px solid ${({ $color }) => $color};
  color: ${({ $color }) => $color};
  font-weight: bold;

  &:hover {
    background: ${({ $color }) => transparentize(0.9, $color)};
  }
`;

const OutlinedButton = styled(StyledButton)`
  ${outlinedButtonStyle}
`;

const tabButtonSpacing = css`
  margin-right: 32px;
  margin-bottom: 32px;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;
const tabButtonActiveStlye = css`
  background: ${(props) => props.$color};
  color: #ffffff;
  cursor: default;
`;

const TabButtonButton = styled(StyledButton)`
  ${outlinedButtonStyle}

  ${tabButtonSpacing}

  &.active {
    ${tabButtonActiveStlye}
  }
`;

const TabLink = styled(NavLink)`
  text-decoration: none;
  ${tabButtonSpacing}

  &.active {
    pointer-events: none;
    cursor: default;
  }

  &.active ${TabButtonButton} {
    ${tabButtonActiveStlye}
  }
`;

export const BasicButton = ({ variant, color, ...props }) => {
  if (variant === "outlined") {
    return <OutlinedButton {...props} $color={color || primaryColor} />;
  }
  return <ContainedButton {...props} $color={color || primaryColor} />;
};

export const LinkButton = ({ to, children, ...props }) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <BasicButton {...props}>{children}</BasicButton>
    </Link>
  );
};

export const TabButton = ({ color, active = false, ...props }) => {
  return (
    <TabButtonButton
      $color={color || primaryColor}
      className={active ? "active" : null}
      disabled={active}
      // active={active}
      {...props}
    />
  );
};

export const TabLinkButton = ({ to, color = primaryColor, ...props }) => {
  return (
    <TabLink to={to} $color={color}>
      <TabButton $color={color} {...props} />
    </TabLink>
  );
};

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
`;

export const MailButton = (props) => {
  const openMail = () => {
    window.open(`mailto:wander@wur.nl`);
  };

  return <BasicButton onClick={openMail} {...props} />;
};
