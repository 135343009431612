import { forwardRef } from "react";
import styled from "styled-components";
import {
  EmText,
  BlockHeader,
  ColumnContainer,
  ContentColumn,
} from "../../MainLayout/styled";
import cityImg from "../../../assets/iStock-1303135600.png";
import { MailButton } from "../../Buttons";
import { Data3D, InteractiveTools, Collaborative } from "../../Icons";

const ContentContainer = styled.div`
  padding-top: 80px;
`;

const ParagraphContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const ParagraphIconContainer = styled.div``;
const ParagraphContentContainer = styled.div``;
const ParagraphHeader = styled.h3`
  margin-top: 0;
`;
const ParagraphContent = styled.p`
  margin-bottom: 32px;
`;

const Column2Img = styled.img`
  width: 100%;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
`;

const OfferingBlock = forwardRef((props, ref) => {
  return (
    <ContentContainer>
      <BlockHeader>
        Offering <EmText>unique</EmText> value
      </BlockHeader>
      <ColumnContainer>
        <ContentColumn>
          <ParagraphContainer>
            <ParagraphIconContainer>
              <Data3D size={80} />
            </ParagraphIconContainer>
            <ParagraphContentContainer>
              <ParagraphHeader>
                3D Data Visualisation
                <br />
                Research & Co-creation
              </ParagraphHeader>
              <ParagraphContent>
                Our core expertise is to turn your complex & abstract data into
                simple augmented experiences. Virtual prototyping and real-time
                immersive simulations can support you at any stage of your
                research journey.
              </ParagraphContent>
            </ParagraphContentContainer>
          </ParagraphContainer>

          <ParagraphContainer>
            <ParagraphIconContainer>
              <InteractiveTools size={80} />
            </ParagraphIconContainer>
            <ParagraphContentContainer>
              <ParagraphHeader>
                Interactive Tools
                <br />
                Advanced Education
              </ParagraphHeader>
              <ParagraphContent>
                We create immersive virtual environments that allow students and
                teachers to interact with data as leverage for education.
              </ParagraphContent>
            </ParagraphContentContainer>
          </ParagraphContainer>

          <ParagraphContainer>
            <ParagraphIconContainer>
              <Collaborative size={80} />
            </ParagraphIconContainer>
            <ParagraphContentContainer>
              <ParagraphHeader>Collaborative environments</ParagraphHeader>
              <ParagraphContent>
                Help boost a strong ecosystem of partnerships to collaborate and
                implement the best practices to solve the socioenvironmental
                challenges.
              </ParagraphContent>
            </ParagraphContentContainer>
          </ParagraphContainer>
        </ContentColumn>

        <ContentColumn style={{ textAlign: "center" }}>
          <Column2Img src={cityImg} />
          <MailButton>book a brainstorming session</MailButton>
        </ContentColumn>
      </ColumnContainer>
    </ContentContainer>
  );
});
export default OfferingBlock;
