import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { LinkButton } from "../components/Buttons";

const Button = styled(LinkButton)`
  display: block;
  margin-top: 64px;
`;

const Project = (props) => {
  return (
    <div {...props} style={{ paddingBottom: 64 }}>
      <Outlet />
      <Button to="..">See all xr-projects</Button>
    </div>
  );
};

export default Project;
