import styled, { css } from "styled-components";
import {
  secondaryColor,
  backgroundLight,
  backgroundLightBlue,
  backgroundDark,
  primaryColor,
} from "../../style/colors";
import grid from "../../assets/Wander_Blok_patroon_02.svg";
import backgroundStars from "../../assets/Wander_sterren_01.png";

export const HomeBlock = styled.section`
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const BlockHeader = styled.h2`
  margin-top: 0;
  font-size: 36px;
`;

export const PageHeader = BlockHeader;

export const EmText = styled.span`
  color: ${secondaryColor};
`;

export const BackgroundSvg = styled.svg.attrs((props) => ({
  viewBox: props.viewBox || "0 0 100 100",
  preserveAspectRatio: props.preserveAspectRatio || "none",
}))`
  width: 100%;
  height: 140px;
  position: absolute;
  ${({ $bottom }) => ($bottom ? `bottom: 0` : `top: 0`)};
  left: 0;
  mix-blend-mode: screen;
`;

export const LightPolygon = styled.polygon`
  fill: ${backgroundLight};
  stroke: none;
`;
export const PrimaryLine = styled.line.attrs({
  vectorEffect: "non-scaling-stroke",
})`
  stroke: ${primaryColor};
  stroke-width: 1;
`;
export const PrimaryPolygon = styled.polygon`
  fill: ${primaryColor};
  stroke: none;
`;
export const WhitePolygon = styled.polygon`
  fill: #ffffff;
  stroke: none;
`;

export const starGridBackground = css`
  background: url(${backgroundStars}), url(${grid}),
    radial-gradient(
      circle at 80% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: repeat, repeat, no-repeat;
  background-position: left top, left top, center top;
  background-size: cover, auto, cover;
`;

export const starGridGreyBackground = ({ $greyPaddingTop = 0 }) => css`
  background: linear-gradient(
      180deg,
      ${backgroundLight} 0%,
      ${backgroundLight} 100%
    ),
    url(${backgroundStars}), url(${grid}),
    radial-gradient(
      circle at 80% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: no-repeat, repeat, repeat, no-repeat;
  background-position: left ${$greyPaddingTop || 0}px, left top, left top,
    center top;
  background-size: auto, cover, auto, cover;
`;

export const starGridPrimaryBackground = css`
  background: url(${backgroundStars}), url(${grid}),
    linear-gradient(180deg, ${primaryColor} 0%, ${primaryColor} 100%);
  background-repeat: repeat, repeat;
  background-position: left top, left top;
  background-size: cover, auto, cover;
`;

export const ParagraphHeader = styled.h3`
  font-weight: normal;
  color: ${secondaryColor};
  break-after: avoid;
`;

export const Paragraph = styled.p`
  line-height: 1.6;
  ${(props) => props.nextColumn && `break-before: column;`}
  widows: 6;
  orphans: 6;
`;

export const ColumnContainer = styled.div`
  display: flex;
  gap: 64px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const ContentColumn = styled.div`
  flex: 1 1 0px;

  @media (max-width: 768px) {
    & + & {
      margin-top: 64px;
    }
  }
`;

export const mobileOnly = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const desktopOnly = css`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ExternalLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: inherit;

  &:visited {
    color: inherit;
  }
`;
