// import styled from "styled-components";
import {
  PageHeader,
  // ParagraphHeader,
  // Paragraph,
  ExternalLink,
  EmText,
} from "../../MainLayout/styled";
import YoutubeEmbed from "../../Media/YoutubeEmbedResponsive";
import {
  MainContainer,
  VideoCaption,
  VideoCaptionDescription,
  // ContentText
} from "../styled";
import windVisualisationImg from "./windVisualisation.png";

const ProjectPage = () => {
  return (
    <MainContainer>
      <PageHeader>Wind Visualisation</PageHeader>

      <YoutubeEmbed videoCode="n7LIrPxqP7Y">
        <img src={windVisualisationImg} alt="Video still" />
      </YoutubeEmbed>

      <VideoCaption>
        XR-<EmText>Project</EmText>
        <br />
        <b>Wind Visualisation</b>
        <VideoCaptionDescription>
          Three-dimensional meter simulation of the wind at the Campus of
          Wageningen carried out with the model MicroHH (
          <ExternalLink href="https://microhh.github.io/">
            https://microhh.github.io/
          </ExternalLink>
          ) within the reserach line of Urban Meteorlogy at the Meteorology and
          Air Quality Section.
        </VideoCaptionDescription>
      </VideoCaption>

      {/* <ContentText>
        <ParagraphHeader>Paragraph1</ParagraphHeader>
        <Paragraph>LoremIpsum</Paragraph>
      </ContentText> */}
    </MainContainer>
  );
};

export default ProjectPage;
