import { forwardRef } from "react";
import styled from "styled-components";
import { ContentWidthContainer } from "../../MainLayout";
import { HomeBlock } from "../../MainLayout/styled";
import { darkBlue } from "../../../style/colors";

const BlockContainer = styled(HomeBlock)`
  min-height: calc(100vh - 100px);
  padding-top: 50px;
  padding-bottom: 100px;
  color: ${darkBlue};
  position: relative;
`;

const ContentContainer = styled.div``;

const Block2 = forwardRef(({ children, ...props }, ref) => {
  return (
    <BlockContainer ref={ref} {...props}>
      <ContentWidthContainer>
        <ContentContainer>{children}</ContentContainer>
      </ContentWidthContainer>
    </BlockContainer>
  );
});
export default Block2;
