import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import ShootingStar from "./ShootingStar";
import Star from "./Star";
import { backgroundDark, backgroundLightBlue } from "../../style/colors";
import grid from "../../assets/Wander_Blok_patroon_02.svg";

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const nightSkyBackground = css`
  background: radial-gradient(
      circle at 80% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: no-repeat;
  background-size: cover;
`;

const nightSkyGridBackground = css`
  background: url(${grid}),
    radial-gradient(
      circle at 80% 50%,
      ${backgroundLightBlue} 0%,
      ${backgroundDark} 80%
    ),
    linear-gradient(180deg, ${backgroundDark} 0%, ${backgroundDark} 100%);
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
`;

const gridBackground = css`
  background: url(${grid});
  background-repeat: repeat;
  background-position: left top;
  background-size: auto;
`;

const MainContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  /* z-index: -2; */
  ${(props) => {
    if (props.showNightBackground && props.showGrid) {
      return nightSkyGridBackground;
    }
    if (props.showNightBackground) {
      return nightSkyBackground;
    }
    if (props.showGrid) {
      return gridBackground;
    }
  }}
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
`;

const StarrySky = ({
  showNightBackground = true,
  showGrid = false,
  starCount = 600,
  className,
  style,
}) => {
  const [starProps, setStarProps] = useState({
    id: 0,
    size: 1,
    duration: 1000,
    direction: 0.125,
    startPosition: [10, 10],
    length: 500,
  });

  const [fixedStars, setFixedStars] = useState([]);

  useEffect(() => {
    const randomStars = [];
    for (let i = 0; i < starCount; i++) {
      randomStars.push(<Star key={i} />);
    }
    setFixedStars(randomStars);
  }, [starCount]);

  useEffect(() => {
    let timers = [];
    const launchStar = (time) => {
      timers.push(
        setTimeout(() => {
          const length = randomIntFromInterval(200, 600);
          const speed = randomIntFromInterval(250, 1000);
          const duration = Math.round((1000 * length) / speed);
          const wait = Math.floor(Math.random() * 2000) + 200;
          setStarProps((prev) => ({
            ...prev,
            id: prev.id + 1,
            startPosition: [
              randomIntFromInterval(0, 100),
              randomIntFromInterval(0, 100),
            ],
            direction: Math.random(),
            duration,
            length,
          }));
          launchStar(duration + wait);
        }, time)
      );
    };
    launchStar(500);

    return () => {
      timers.forEach((timer) => {
        clearTimeout(timer);
      });
    };
  }, []);
  return (
    <MainContainer
      showNightBackground={showNightBackground}
      showGrid={showGrid}
      className={className}
      style={style}
    >
      <InnerContainer>
        {starProps.id > 0 && <ShootingStar key={starProps.id} {...starProps} />}
        {fixedStars}
      </InnerContainer>
    </MainContainer>
  );
};

export default StarrySky;
