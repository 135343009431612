import styled from "styled-components";
import { Link } from "react-router-dom";
import StarrySky from "../components/StarrySky";

const MainContainer = styled.div`
  height: 100vh;
  color: #ffffff;
  padding: 5%;
  box-sizing: border-box;
`;

const HomeLink = styled(Link)`
  color: #fff;
  &:visited {
    color: #fff;
  }
`;

const NoMatch = () => {
  return (
    <MainContainer>
      <StarrySky />
      <p style={{ fontSize: "200%" }}>Content not yet available</p>
      <HomeLink to="/">Go to homepage</HomeLink>
    </MainContainer>
  );
};

export default NoMatch;
