import { useState } from "react";
import YouTube from "react-youtube";
import styled from "styled-components";
import { Replay as ReplayIcon } from "../Icons";

// 9/16 * 100 = 56,25
const ResponsiveContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
`;

const PlayButtonContainer = styled.div`
  background: #ffffff50;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 16px;
  opacity: 0;

  transition: opacity 500ms;
`;

const PlayButtonIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 100ms all ease;
`;

const PlayButton = () => {
  return (
    <PlayButtonContainer>
      <PlayButtonIcon>
        <ReplayIcon size={74} />
      </PlayButtonIcon>
    </PlayButtonContainer>
  );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  ${(props) =>
    !props.hasChildren &&
    `
   & ${PlayButtonContainer} {
    opacity: 0.8;
  }`};
  &:hover ${PlayButtonContainer} {
    opacity: 1;
  }
`;

const IFrame = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const VideoOverlay = ({ player, videoStatus, children, ...props }) => {
  const playVideo = () => {
    if (player) {
      player.playVideo();
    }
  };

  if (videoStatus === YouTube.PlayerState.ENDED) {
    return (
      <Overlay onClick={playVideo} {...props} hasChildren={!!children}>
        {children}
        <PlayButton />
      </Overlay>
    );
  }
  return null;
};

const YoutubeEmbed = ({
  videoCode,
  title = "YouTube video player",
  children,
  ...props
}) => {
  const [videoStatus, setVideoStatus] = useState(YouTube.PlayerState.UNSTARTED);
  const [player, setPlayer] = useState();

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      //   autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };
  return (
    <ResponsiveContainer {...props}>
      <IFrame
        videoId={videoCode}
        opts={opts}
        onStateChange={(e) => {
          setPlayer(e.target);
          setVideoStatus(e.data);
        }}
      />
      <VideoOverlay videoStatus={videoStatus} player={player}>
        {children}
      </VideoOverlay>
    </ResponsiveContainer>
  );
};

export default YoutubeEmbed;
